import React, { Component } from "react";
import Feathers from "../../redux/FeathersRedux";
import ReactTable from "react-table";
import { Button, Grid, Form, Label, Popup } from "semantic-ui-react";
import { connect } from "react-redux";
import swal from "sweetalert2";
import moment from "moment";
import "moment/locale/es";
import { exportToXls } from "../../utils/Utils";
import RemissionRodillosModal from "./RemissionRodillosModal";
import TasksFilter from "../../components/FIlters/TasksFilter";
import Config from "../../config";
import { laravelService } from "../../utils/laravelService";

const renderClientSearchResults = ({ id, nombre }) => (
  <Label key={id} content={nombre} />
);

const renderMaterialSearchResults = ({ id, nombre }) => (
  <Label key={id} content={nombre} />
);

class RemissionList extends Component {
  constructor() {
    super();

    this.state = {
      orders: [],
      total: 0,
      page: 0,
      loading: false,
      pageSize: 25,
      filters: ["pedido", "partida", "costumer", "material", "entrega"],
      filterOptions: {
        cliente_id: null,
        material_id: null,
        ref_sae: null,
        pedido_id: null,
        partida_id: null,
        material_name: null,
      },
      filterValues: {
        rodilloValue: null,
        partidaValue: null,
        pedidoValue: null,
        fechaEntrega: null,
      },
      cliente: null,
      material: null,
      clienteLoading: null,
      materialLoading: null,
      clienteResults: null,
      materialResults: null,
      currentRow: {},
      isInfoOpened: false,
      isEditOpened: false,
      modalOpened: false,
      mancheta_id: null,
      results: null,
      client: "",
      $sort: {
        $fechaAltaSortDirection: null,
        $fechaEntregaSortDirection: null,
        $saeSortDirection: null,
      },
      columna: "",
      sortDirection: true,
    };

    this.closeModal = this.closeModal.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  handleSort = (array) => {
    let column = this.state.columna;
    switch (column.Header) {
      case "SAE":
        array.data.sort((a, b) => {
          return a.ref_sae - b.ref_sae;
        });
        break;
      case "Cliente":
        array.data.sort((a, b) => {
          const nameA = a.cliente.nombre.toUpperCase(); // ignore upper and lowercase
          const nameB = b.cliente.nombre.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Fecha Ent.":
        array.data.sort((a, b) => {
          const nameA = a.fecha_entrega.toUpperCase(); // ignore upper and lowercase
          const nameB = b.fecha_entrega.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "1er Producto":
        array.data.sort((a, b) => {
          let nameA = a.partidas.producto.nombre.toUpperCase();
          let nameB = b.partidas.producto.nombre.toUpperCase();

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Dureza":
        array.data.sort((a, b) => {
          let A = a.partidas.dureza ? a.partidas.dureza.valor : 0;
          let B = b.partidas.dureza ? b.partidas.dureza.valor : 0;
          return A - B;
        });
        break;
      case "Cantidad":
        array.data.sort((a, b) => {
          let A = a.partidas.cantidad;
          let B = b.partidas.cantidad;
          return A - B;
        });
        break;
      case "P. Mancheta":
        array.data.sort((a, b) => {
          let A = a.manchetas ? a.manchetas.parte : "";
          let B = b.manchetas ? b.manchetas.parte : "";
          return A - B;
        });
        break;
      case "Status":
        array.data.sort((a, b) => {
          let A = a.status_id;
          let B = b.status_id;
          return A - B;
        });
        break;
      default:
        break;
    }
    if (this.state.sortDirection) {
      array.data.reverse();
    }
    return array;
  };
  componentDidMount() {
    this.getStatusFilterOptions();
  }

  getStatusFilterOptions = async (_) => {
    let status_pedido = await Feathers.service("status_pedido").find({
      query: {
        $limit: -1,
        activo: 1,
      },
    });

    this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        status_options: status_pedido.map((v) => {
          return {
            key: v.id,
            text: v.nombre,
            value: v.id,
          };
        }),
      },
    });
  };

  fetchData = (state = {}) => {
    this.setState(
      {
        loading: true,
      },
      async () => {
        let query = {};

        if (this.state.pageSize) {
          query["page_size"] = this.state.pageSize;
        }

        if (this.state.columna && this.state.sortDirection !== null) {
          query["sort_column"] = this.state.columna;
          query["sort_direction"] = this.state.sortDirection ? "ASC" : "DESC";
        }

        if (this.state.filterValues.pedidoValue) {
          query["pedido_id"] = this.state.filterValues.pedidoValue;
        }

        if (this.state.filterValues.partidaValue) {
          query["partida_id"] = this.state.filterValues.partidaValue;
        }

        if (this.state.filterOptions.cliente_id) {
          query["cliente_id"] = this.state.filterOptions.cliente_id;
        }

        if (this.state.filterOptions.material_id) {
          query["material_id"] = this.state.filterOptions.material_id;
        }

        if (this.state.filterValues.fechaEntrega) {
          query["fecha_entrega"] = moment(
            this.state.filterValues.fechaEntrega
          ).format("YYYY-MM-DD HH:mm:ss");
        }

        await laravelService
          .get(`reports/remissions?page=${this.state.page + 1}`, {
            params: query,
          })
          .then((res) => {
            this.setState({
              orders: res.data.data,
              total: res.data.total,
              loading: false,
              page: state.page,
              pageSize: state.pageSize,
            });
          });
      }
    );
  };

  createRemission = async (order) => {
    if (!order.cliente_rfc) {
      swal({
        title: "Datos del cliente incompletos",
        text: "Para continuar agregue los datos de RFC en el cliente.",
        type: "error",
        confirmButtonText: "Aceptar",
        reverseButtons: true,
      });
      return;
    }

    const pattern =
      "[A-Z&Ñ]{3,4}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[A-Z0-9]{2}[0-9A]";

    const rfc = order.cliente_rfc;

    if (!rfc.match(pattern)) {
      swal({
        title: "RFC del cliente inválido",
        text: "Verifique los datos",
        type: "error",
        confirmButtonText: "Aceptar",
        reverseButtons: true,
      });
      return;
    }

    const rodillosOrderId = this.getRodillosFromOrder(order).map(
      (rodillo) => rodillo.id
    );

    await Feathers.service("pedido")
      .patch(order.pedido_id, {
        status_id: 3,
      })
      .then(() => {
        Feathers.service("rodillo")
          .patch(
            null,
            {
              stage: 13,
              fecha_terminado: moment().format("YYYY-MM-DD HH:mm:ss"),
            },
            {
              query: {
                id: {
                  $in: rodillosOrderId,
                },
              },
            }
          )
          .then(() => {
            // window.open(
            //   "",
            //   "_blank"
            // ).location.href = `https://facturacion.bingham.acsyt.com/api/orders/remission/${order.id}`;
            window.open(
              "",
              "_blank"
            ).location.href = `${Config.laravelUrl}/api/orders/remission/${order.pedido_id}`;
            // wait 2000ms to refresh data since the current order gets updated on the endpoint above
            setTimeout(() => {
              this.fetchData(this.state);
            }, 2000);
          });
      })
      .catch((error) => {
        console.log(error, "averrr");
        swal({
          title: "Ocurrió un error",
          type: "error",
          showCancelButton: true,
          cancelButtonColor: "#d33",
          confirmButtonText: "Si",
          cancelButtonText: "No",
          reverseButtons: true,
        });
      });
  };

  closeModal() {
    this.setState({
      modalOpened: false,
    });
  }

  openModal(row) {
    console.log("row", row);
    this.setState({
      currentRow: row,
      modalOpened: true,
    });
  }

  getRodillosFromOrder(order) {
    return Object.keys(order).length
      ? order.partidas
        ? order.partidas.rodillo
          ? order.partidas.rodillo.length
            ? order.partidas.rodillo
            : [order.partidas.rodillo]
          : []
        : []
      : [];
  }

  handleClienteResultSelect = async (e, { result }) => {
    this.setState({ cliente: result.nombre });

    this.setState(
      {
        filterOptions: {
          ...this.state.filterOptions,
          cliente_id: result.id,
        },
      },
      () => this.fetchData(this.state)
    );
  };

  handleMaterialResultSelect = async (e, { result }) => {
    this.setState({ material: result.nombre });

    this.setState(
      {
        filterOptions: {
          ...this.state.filterOptions,
          material_id: result.id,
        },
      },
      () => this.fetchData(this.state)
    );
  };

  handleClienteSearchChange = (e, { value }) => {
    this.setState({ clienteLoading: true, cliente: value });

    this.timeout = setTimeout(() => {
      if (this.state.cliente.length < 1) return this.resetClienteComponent();

      Feathers.service("cliente")
        .find({
          query: {
            nombre: { $like: `%${value}%` },
            activo: 1,
            $limit: 3,
          },
        })
        .then((res) => {
          this.setState({
            clienteLoading: false,
            clienteResults: res.data,
          });
        });
    }, 500);
  };

  handleMaterialSearchChange = (e, { value }) => {
    this.setState({ materialLoading: true, material: value });

    setTimeout(() => {
      if (this.state.material.length < 1) return this.resetMaterialComponent();

      Feathers.service("material")
        .find({
          query: {
            nombre: { $like: `%${value}%` },
            activo: 1,
            $limit: 3,
          },
        })
        .then((res) => {
          this.setState({
            materialLoading: false,
            materialResults: res.data,
          });
        });
    }, 500);
  };

  onPartidaChange = (partida) => {
    this.setState(
      {
        filterValues: {
          ...this.state.filterValues,
          partidaValue: partida,
        },
      },
      () => this.fetchData(this.state)
    );
  };

  onChangePedido = (pedido) => {
    this.setState(
      {
        filterValues: {
          ...this.state.filterValues,
          pedidoValue: pedido,
        },
      },
      () => this.fetchData(this.state)
    );
  };

  onDateChange = (date) => {
    const dateFormat = moment(date).format("YYYY-MM-DD");
    this.setState(
      {
        filterValues: {
          ...this.state.filterValues,
          fechaEntrega: dateFormat,
        },
      },
      () => this.fetchData(this.state)
    );
  };

  resetClienteComponent = () => {
    this.setState({ clienteLoading: false, clienteResults: [], cliente: "" });
  };

  resetMaterialComponent = () => {
    this.setState({
      materialLoading: false,
      materialResults: [],
      material: "",
    });
  };

  handleCleanButton = async () => {
    this.resetClienteComponent();
    this.resetMaterialComponent();
    this.setState(
      {
        filterValues: {
          cliente_id: null,
          material_id: null,
          ref_sae: null,
          pedido_id: null,
          partida_id: null,
          material_name: null,
        },
        filterOptions: {
          rodilloValue: null,
          partidaValue: null,
          pedidoValue: null,
          fechaEntrega: null,
        },
        material: null,
      },
      () => {
        this.fetchData(this.state);
      }
    );
  };

  _handleFilterButton = () => {
    this.fetchData(this.state);
  };

  render() {
    return (
      <div>
        <Grid.Row>
          <Grid.Column>
            <Form>
              <Form.Group>
                <TasksFilter
                  loading={this.state.loading}
                  clienteLoading={this.state.clienteLoading}
                  filters={this.state.filters}
                  costumer={this.state.cliente || ""}
                  costumers={this.state.clienteResults}
                  onSelectCostumer={this.handleClienteResultSelect}
                  onSearchCostumerChange={this.handleClienteSearchChange}
                  costumerResultRenderer={renderClientSearchResults}
                  partidaValue={this.state.filterValues.partidaValue || ""}
                  onChangePartida={this.onPartidaChange}
                  fechaEntrega={this.state.filterValues.fechaEntrega || ""}
                  onDateChange={this.onDateChange}
                  materialLoading={this.state.materialLoading}
                  materials={this.state.materialResults}
                  material={this.state.material ? this.state.material : ""}
                  handleMaterialResultSelect={this.handleMaterialResultSelect}
                  handleMaterialSearchChange={this.handleMaterialSearchChange}
                  renderMaterialSearchResults={renderMaterialSearchResults}
                  pedidoValue={this.state.filterValues.pedidoValue || ""}
                  onPedidoChange={this.onChangePedido}
                />
                <Form.Field>
                  <label>Filtrar</label>
                  <Button
                    basic
                    circular
                    icon="filter"
                    onClick={this._handleFilterButton}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Limpiar</label>
                  <Button
                    basic
                    color="red"
                    circular
                    icon="x"
                    onClick={this.handleCleanButton}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Exportar</label>
                  <Button
                    basic
                    color="green"
                    circular
                    icon="file excel outline"
                    onClick={() => {
                      console.log("http", Config, process.env);
                      window.open(
                        "",
                        "_blank"
                      ).location.href = `${Config.laravelUrl}/api/excel/remissions`;
                    }}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
        <ReactTable
          columns={[
            {
              Header: "OT",
              id: "pedido_id",
              maxWidth: 90,
              accessor: (d) => d.pedido_id,
            },
            {
              Header: "SAE",
              id: "ref_sae",
              maxWidth: 90,
              accessor: (d) => d.ref_sae,
            },
            {
              Header: "Partida",
              id: "partida_id",
              maxWidth: 90,
              accessor: (d) => d.partida_id,
            },
            {
              id: "cliente_nombre",
              Header: "Cliente",
              accessor: "cliente_nombre",
            },
            {
              Header: "Fecha Alta",
              id: "fecha_alta",
              accessor: (d) => new Date(d.fecha_alta).toLocaleDateString(),
            },
            {
              Header: "Fecha Entrega",
              id: "fecha_entrega",
              accessor: (d) => new Date(d.fecha_entrega).toLocaleDateString(),
            },
            {
              Header: "Material",
              id: "material_nombre",
              maxWidth: 250,
              accessor: (d) => d.material_nombre,
            },
            {
              Header: "Cantidad",
              id: "cantidad",
              maxWidth: 100,
              accessor: (d) => d.cantidad,
            },
            {
              Header: "DN",
              id: "dn",
              maxWidth: 80,
              accessor: (d) => d.dn,
            },
            {
              Header: "DE",
              id: "de",
              maxWidth: 80,
              accessor: (d) => d.de,
            },
            {
              Header: "LC",
              id: "lc",
              maxWidth: 80,
              accessor: (d) => d.lc,
            },
            {
              Header: "LT",
              id: "lt",
              maxWidth: 80,
              accessor: (d) => d.lt,
            },
            {
              Header: "Kilos",
              id: "kilos",
              maxWidth: 80,
              accessor: (d) => d.kilos,
            },
            {
              Header: "Dureza",
              id: "dureza_valor",
              accessor: (d) => d.dureza_valor,
              maxWidth: 80,
            },
            {
              Header: "Progreso",
              id: "rodillos_remission_total",
              Cell: (row) =>
                `${row.original.rodillos_remission_total}/${row.original.total_rodillos}`,
              maxWidth: 80,
            },
            {
              Header: "Acciones",
              Cell: (row) => {
                return (
                  <div>
                    <Popup
                      content="Ver rodillos"
                      trigger={
                        <Button
                          size="mini"
                          compact
                          basic
                          color="blue"
                          icon="eye"
                          onClick={() => this.openModal(row.original)}
                        />
                      }
                    />
                    <Popup
                      content="Generar remisión"
                      trigger={
                        <Button
                          disabled={
                            row.original.rodillos_remission_total !==
                            row.original.total_rodillos
                          }
                          size="mini"
                          compact
                          basic
                          color="green"
                          icon="file pdf outline"
                          onClick={() => this.createRemission(row.original)}
                        />
                      }
                    />
                  </div>
                );
              },
            },
          ]}
          manual
          data={this.state.orders}
          loading={this.state.loading}
          pages={Math.ceil(this.state.total / this.state.pageSize)}
          page={this.state.page}
          pageIndex={this.state.page}
          onFetchData={this.fetchData}
          defaultPageSize={this.state.pageSize}
          style={{ textAlign: "center" }}
          onSortedChange={async (newSorted, column, shiftKey) => {
            let direction;
            if (newSorted[0].id == this.state.columna) {
              direction = !this.state.sortDirection;
            } else {
              console.log(this.state.sortDirection);
              direction = !this.state.sortDirection;
            }

            this.setState(
              {
                columna: newSorted[0].id,
                sortDirection: this.state.sortDirection ? false : true,
              },
              () => {
                this.fetchData();
              }
            );
          }}
        />
        <RemissionRodillosModal
          isOpened={this.state.modalOpened}
          data={this.state.currentRow}
          onClose={this.closeModal}
        />
      </div>
    );
  }
}

export default connect(null, null, null, {
  withRef: true,
})(RemissionList);
