import React, { Component } from "react";
import Feathers from "../../redux/FeathersRedux";
import Config from "../../config";
import { Button, Icon, Modal, Label, Grid } from "semantic-ui-react";

export default class TaskFileModal extends Component {
  constructor() {
    super();
    this.state = {
      files: [],
    };
  }


  fetchFiles() {
    const id = this.props.idTask;
    Feathers.service("tarea_file")
      .find({
        query: {
          tarea_calendario_id: id,
        },
      })
      .then((res) => {
        this.setState({
          files: res.data,
        });
      });
  }

  componentDidMount() {
    this.fetchFiles();
  }


  render() {
    return (
      <Modal open={this.props.open} onClose={this.props.onClose} size="small">
        <Modal.Header>Entregables de esta tarea</Modal.Header>
        <Modal.Content>
          <Grid columns={2}>
            {this.state.files.map((file) => (
              <Grid.Column key={file.id}>
                <section
                  style={{
                    display: "flex",
                    boxShadow: "0px 3px 6px #00000029",
                    borderRadius: "5px",
                    height: "50px",
                    padding: "0.5rem",
                    color: "red",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "10%" }}>
                    <Icon name="file pdf outline" size="large" />
                  </div>
                  <div style={{ width: "90%" }}>
                    <a
                      href={`${Config.apiUrl}/documents/tasks/${file.document}`}
                      target="_blank"
                    >
                      {file.document}
                    </a>
                  </div>
                </section>
              </Grid.Column>
            ))}
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button icon="check" content="Ok" onClick={this.props.onClose} />
        </Modal.Actions>
      </Modal>
    );
  }
}
