import React, { Component } from "react";
import Feathers from "../../redux/FeathersRedux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import TaskStatus from "../../components/Tasks/TaskStatus";
import {
  Button,
  Grid,
  Header,
  Icon,
  Modal,
  Container,
  Form,
  Search,
  Label,
} from "semantic-ui-react";
import swal from "sweetalert2";
import moment from "moment";
import TaskContainer from "../../components/Tasks/Construction/ConstructionTask";
import { notify } from "react-notify-toast";
import Config from "../../config/index";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { exportToXls } from "../../utils/Utils";
import LoaderData from "../LoaderData";
import ObservationModal from "../../components/ObservationModal";
import Toggle from "react-toggle";
import TasksFilter from "../../components/FIlters/TasksFilter";

const SERVICE = "construccion";

const renderClientSearchResults = ({ id, nombre }) => (
  <Label key={id} content={nombre} />
);

const renderMaterialSearchResults = ({ id, nombre }) => (
  <Label key={id} content={nombre} />
);

class ConstruccionRodilloContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      loadingSearch: false,
      loadingSAESearch: false,
      loadingFormulaSearch: false,
      tasks: [],
      total: 0,
      page: 0,
      pageSize: 100,
      ///
      ready: false,
      task: null,
      modalOpen: false,
      imageToOpen: "",
      results: null,
      shouldExport: false,

      filters: [
        "pedido",
        "partida",
        "rodillo",
        "costumer",
        "material",
        "entrega",
      ],
      filterOptions: {
        cliente_id: null,
        material_id: null,
        ref_sae: null,
        pedido_id: null,
        partida_id: null,
      },
      filterValues: {
        rodilloValue: null,
        partidaValue: null,
        pedidoValue: null,
        fechaEntrega: null,
      },
      cliente: null,
      material: null,
      clienteLoading: null,
      materialLoading: null,
      clienteResults: null,
      materialResults: null,

      formula: "",
      resultsSae: [],
      resultsFormula: [],
      modalOpened: false,
      currentRow: {},
      columna: "",
      sortDirection: false,
      showSae: false,
      tableColumns: [
        {
          Header: "OT",
          resizable: false,
          headerStyle: { fontSize: 16 },
          maxWidth: 80,
          Cell: (row) => {
            if (row.original.rodillo.partida.pedido)
              return <span>{row.original.rodillo.partida.pedido.id}</span>;
            return "---";
          },
        },
        {
          Header: "SAE",
          resizable: false,
          headerStyle: { fontSize: 16 },
          maxWidth: 80,
          Cell: (row) => {
            if (row.original.rodillo.partida.pedido)
              return <span>{row.original.rodillo.partida.pedido.ref_sae}</span>;
            return "---";
          },
        },
        {
          Header: "Partida",
          headerStyle: { fontSize: 16 },
          id: "partida_id",
          accessor: (d) => {
            if (d.rodillo.partida_id) return d.rodillo.partida_id;
            return "---";
          },
        },
        {
          Header: "Rodillo",
          headerStyle: { fontSize: 16 },
          Cell: (row) => {
            if (row.original.rodillo.partida.dibujo) {
              return (
                <a
                  href="#"
                  onClick={() =>
                    this.handleOpen(row.original.rodillo.partida.dibujo.imagen)
                  }
                >
                  {" "}
                  {row.original.rodillo.id}
                </a>
              );
            } else {
              return <span>{row.original.rodillo.id}</span>;
            }
          },
        },

        {
          Header: "Cliente",
          resizable: false,
          maxWidth: 250,
          headerStyle: { fontSize: 16 },
          Cell: (row) => {
            if (row.original.rodillo.partida.pedido)
              return (
                <span>
                  {row.original.rodillo.partida.pedido.cliente.nombre}
                </span>
              );

            return "---";
          },
        },
        {
          Header: "Material",
          resizable: false,
          maxWidth: 250,
          headerStyle: { fontSize: 16 },
          Cell: (row) => {
            if (row.original.rodillo.partida.material)
              return (
                <span>{row.original.rodillo.partida.material.nombre}</span>
              );

            return "---";
          },
        },
        {
          Header: "Fecha Ent.",
          resizable: false,
          maxWidth: 120,
          headerStyle: { fontSize: 16 },
          Cell: (row) => {
            if (row.original.rodillo.partida.pedido)
              return (
                <span>
                  {moment(
                    row.original.rodillo.partida.pedido.fecha_entrega
                  ).format("DD-MM-YYYY")}
                </span>
              );

            return "---";
          },
        },

        {
          Header: "Dureza",
          resizable: false,
          headerStyle: { fontSize: 16 },
          maxWidth: 80,
          Cell: (row) => {
            if (row.original.rodillo.partida.dureza)
              return <span>{row.original.rodillo.partida.dureza.valor}</span>;

            return "---";
          },
        },
        {
          Header: "DN",
          resizable: false,
          headerStyle: { fontSize: 16 },
          maxWidth: 80,
          Cell: (row) => {
            return <span>{row.original.rodillo.partida.dn}</span>;
          },
        },
        {
          Header: "DE",
          resizable: false,
          headerStyle: { fontSize: 16 },
          maxWidth: 80,
          Cell: (row) => {
            return <span>{row.original.rodillo.partida.de}</span>;
          },
        },
        {
          Header: "LC",
          resizable: false,
          headerStyle: { fontSize: 16 },
          maxWidth: 80,
          Cell: (row) => {
            return <span>{row.original.rodillo.partida.lc}</span>;
          },
        },
        {
          Header: "LT",
          resizable: false,
          headerStyle: { fontSize: 16 },
          maxWidth: 80,
          Cell: (row) => {
            return <span>{row.original.rodillo.partida.lt}</span>;
          },
        },
        {
          Header: "Kilos",
          resizable: false,
          headerStyle: { fontSize: 16 },
          maxWidth: 80,
          Cell: (row) => {
            if (row.original.rodillo.partida.kilos)
              return <span>{row.original.rodillo.partida.kilos}</span>;

            return "---";
          },
        },
        {
          Header: "Cant.",
          resizable: false,
          headerStyle: { fontSize: 16 },
          maxWidth: 100,
          Cell: (row) => {
            if (row.original.rodillo.partida.cantidad)
              return <span>{row.original.rodillo.partida.cantidad}</span>;

            return "---";
          },
        },
        {
          Header: "Acciones",
          sortable: false,
          resizable: false,
          headerStyle: { fontSize: 16 },
          Cell: this.renderActions,
        },
      ],
    };

    this.renderList = this.renderList.bind(this);
    this.fetchData = this.fetchData.bind(this);
    // this.renderActions = this.renderActions.bind(this);
    this.checkActiveTasks = this.checkActiveTasks.bind(this);
    this.finishTask = this.finishTask.bind(this);
    this.initTask = this.initTask.bind(this);
    this.updateTask = this.updateTask.bind(this);
    this.cancelTaskByIncidence = this.cancelTaskByIncidence.bind(this);
    this.viewTask = this.viewTask.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.goBack = this.goBack.bind(this);
    this.handleSaeToggle = this.handleSaeToggle.bind(this);
  }

  handleSaeToggle = () => {
    this.setState(
      {
        showSae: !this.state.showSae,
      },
      () => {
        console.log(this.state);
      }
    );
    console.log(this.state.showSae);
  };

  handleSort = (array) => {
    let column = this.state.columna;
    switch (column.Header) {
      case "SAE":
        array.sort((a, b) => {
          return (
            a.rodillo.partida.pedido.ref_sae - b.rodillo.partida.pedido.ref_sae
          );
        });
        break;
      case "Cliente":
        array.sort((a, b) => {
          const nameA = a.rodillo.partida.pedido.cliente.nombre.toUpperCase(); // ignore upper and lowercase
          const nameB = b.rodillo.partida.pedido.cliente.nombre.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Rodillo":
        array.sort((a, b) => {
          let A = a.rodillo.id;
          let B = b.rodillo.id;
          return A - B;
        });
        break;
      case "Fecha Ent.":
        array.sort((a, b) => {
          const nameA = a.rodillo.partida.pedido.fecha_entrega.toUpperCase(); // ignore upper and lowercase
          const nameB = b.rodillo.partida.pedido.fecha_entrega.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Material":
        array.sort((a, b) => {
          let nameA = a.rodillo.partida.material.nombre
            ? a.rodillo.partida.material.nombre.toUpperCase()
            : ""; // ignore upper and lowercase
          let nameB = b.rodillo.partida.material.nombre.nombre
            ? b.rodillo.partida.material.nombre.toUpperCase()
            : ""; // ignore upper and lowercase

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Dureza":
        array.sort((a, b) => {
          let A = a.rodillo.partida.dureza.valor;
          let B = b.rodillo.partida.dureza.valor;
          return A - B;
        });
        break;
      case "DN":
        array.sort((a, b) => {
          let A = a.rodillo.partida.dn;
          let B = b.rodillo.partida.dn;
          return A - B;
        });
        break;
      case "DE":
        array.sort((a, b) => {
          let A = a.rodillo.partida.de;
          let B = b.rodillo.partida.de;
          return A - B;
        });
        break;
      case "LC":
        array.sort((a, b) => {
          let A = a.rodillo.partida.lc;
          let B = b.rodillo.partida.lc;
          return A - B;
        });
        break;
      case "LT":
        array.sort((a, b) => {
          let A = a.rodillo.partida.lt;
          let B = b.rodillo.partida.lt;
          return A - B;
        });
        break;
      case "Kilos":
        array.sort((a, b) => {
          let A = a.rodillo.partida.kilos;
          let B = b.rodillo.partida.kilos;
          return A - B;
        });
        break;
      case "Cantidad":
        array.sort((a, b) => {
          let A = a.rodillo.partida.cantidad;
          let B = b.rodillo.partida.cantidad;
          return A - B;
        });
        break;
      default:
        break;
    }
    if (this.state.sortDirection) {
      array.data.reverse();
    }
    return array;
  };

  openModal = (row) => {
    this.setState({
      modalOpened: true,
      currentRow: row.rodillo.partida.observaciones,
    });
  };

  closeModal = () => {
    this.setState({
      modalOpened: false,
    });
  };

  goBack() {
    this.setState({ task: null });
  }

  handleOpen = (logicImageUrl) => {
    var imageToOpen = Config.apiUrl + logicImageUrl;
    this.setState({ modalOpen: true, imageToOpen: imageToOpen });
  };

  handleClose = () => {
    this.setState({ modalOpen: false });
  };

  componentDidMount() {
    this.checkActiveTasks();
  }

  checkActiveTasks() {
    Feathers.service(SERVICE)
      .find({
        query: {
          usuario_id: this.props.user.id,
          status_tarea_id: 1,
          $table: true,
        },
      })
      .then((res) => {
        if (res.total > 0) {
          if (res.data[0].rodillo.esMancheta === 0) {
            this.setState({
              task: res.data[0],
              ready: true,
            });
          } else {
            this.setState({
              ready: true,
            });
          }
        } else {
          this.setState({
            ready: true,
          });
        }
      });
  }

  async fetchData(state = {}) {
    this.setState({
      loading: true,
    });

    let partidas = await Feathers.service("partida").find({
      query: {
        $limit: "-1",
        producto_id: {
          $in: [1],
        },
        $select: ["id"],
      },
    });

    let partidas_validas_ids = partidas.map((v) => v.id);

    let rodillos = await Feathers.service("rodillo").find({
      query: {
        $limit: "-1",
        partida_id: {
          $in: partidas_validas_ids,
        },
        $or: [{ status_tarea_id: null }, { status_tarea_id: 1 }],
        $select: ["id "],
      },
    });

    let rodillosValidos = rodillos.map((v) => v.id);
    let pageSize = state.pageSize || 100;

    let query = {
      rodillo_id: {
        $in: rodillosValidos,
      },
      $limit: pageSize,
      $skip: pageSize * state.page,
      $or: [{ status_tarea_id: null }, { status_tarea_id: 1 }],
      $sort: {
        id: -1,
      },
    };

    if (this.state.filterOptions.material_id !== null) {
      let partidas_de_cliente = await Feathers.service("partida").find({
        query: {
          $limit: "-1",
          material_id: this.state.filterOptions.material_id,
        },
      });

      let rodillos_del_cliente_con_las_partidas_validas = await Feathers.service(
        "rodillo"
      ).find({
        query: {
          $limit: "-1",
          partida_id: {
            $in: partidas_de_cliente.map((v) => v.id),
          },
        },
      });

      let los_id_de_los_rodillos_del_cliente = rodillos_del_cliente_con_las_partidas_validas.map(
        (v) => v.id
      );

      query["rodillo_id"] = {
        $in: los_id_de_los_rodillos_del_cliente,
      };
    }

    if (this.state.filterOptions.cliente_id !== null) {
      let pedidos_del_cliente = await Feathers.service("pedido").find({
        query: {
          $limit: "-1",
          cliente_id: this.state.filterOptions.cliente_id,
        },
      });

      let pedidos_ids = pedidos_del_cliente.map((v) => v.id);

      let partidas_de_los_pedidos = await Feathers.service("partida").find({
        query: {
          $limit: "-1",
          producto_id: {
            $in: [1],
          },
          pedido_id: {
            $in: pedidos_ids,
          },
        },
      });

      let partidas_ids = partidas_de_los_pedidos.map((v) => v.id);

      let rodillos_de_las_partidas = await Feathers.service("rodillo").find({
        query: {
          $limit: "-1",
          partida_id: {
            $in: partidas_ids,
          },
        },
      });

      let rodillos_ids = rodillos_de_las_partidas.map((v) => v.id);

      query["rodillo_id"] = {
        $in: rodillos_ids,
      };
    }

    if (this.state.filterValues.pedidoValue) {
      let pedidos_id = await Feathers.service("pedido").find({
        query: {
          $limit: "-1",
          id: this.state.filterValues.pedidoValue,
        },
      });

      let pedidos_ids = pedidos_id.map((v) => v.id);

      let partidas_de_los_pedidos = await Feathers.service("partida").find({
        query: {
          $limit: "-1",
          pedido_id: {
            $in: pedidos_ids,
          },
        },
      });

      let partidas_ids = partidas_de_los_pedidos.map((v) => v.id);

      let rodillos_de_las_partidas = await Feathers.service("rodillo").find({
        query: {
          $limit: "-1",
          partida_id: {
            $in: partidas_ids,
          },
        },
      });

      let rodillos_ids = rodillos_de_las_partidas.map((v) => v.id);

      query["rodillo_id"] = {
        $in: rodillos_ids,
      };
    }

    if (this.state.filterValues.partidaValue) {
      let rodillos_de_las_partidas = await Feathers.service("rodillo").find({
        query: {
          $limit: "-1",
          partida_id: this.state.filterValues.partidaValue,
        },
      });

      let rodillos_ids = rodillos_de_las_partidas.map((v) => v.id);

      query["rodillo_id"] = {
        $in: rodillos_ids,
      };
    }

    if (this.state.filterValues.rodilloValue) {
      let rodillos_de_las_partidas = await Feathers.service("rodillo").find({
        query: {
          $limit: "-1",
          id: this.state.filterValues.rodilloValue,
        },
      });

      let rodillos_ids = rodillos_de_las_partidas.map((v) => v.id);

      query["rodillo_id"] = {
        $in: rodillos_ids,
      };
    }

    if (this.state.filterValues.fechaEntrega) {
      let pedidos_id = await Feathers.service("pedido").find({
        query: {
          $limit: "-1",
          fecha_entrega: this.state.filterValues.fechaEntrega,
        },
      });

      let pedidos_ids = pedidos_id.map((v) => v.id);

      let partidas_de_los_pedidos = await Feathers.service("partida").find({
        query: {
          $limit: "-1",
          pedido_id: {
            $in: pedidos_ids,
          },
        },
      });

      let partidas_ids = partidas_de_los_pedidos.map((v) => v.id);

      let rodillos_de_las_partidas = await Feathers.service("rodillo").find({
        query: {
          $limit: "-1",
          partida_id: {
            $in: partidas_ids,
          },
        },
      });

      let rodillos_ids = rodillos_de_las_partidas.map((v) => v.id);

      query["rodillo_id"] = {
        $in: rodillos_ids,
      };
    }

    Feathers.service(SERVICE)
      .find({
        query,
      })
      .then((res) => {
        //let resdata = JSON.parse(JSON.stringify(res.data));
        let resdata = res.data ? res.data : res;

        resdata = this.handleSort(resdata);

        if (this.state.columna === "") {
          resdata.sort((a, b) => {
            const nameA = a.rodillo.partida.pedido.fecha_entrega.toUpperCase();
            const nameB = b.rodillo.partida.pedido.fecha_entrega.toUpperCase();

            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          });
        }

        if (!this.state.shouldExport) {
          this.setState({
            tasks: resdata,
            total: res.total,
            loading: false,
            page: state.page,
            pageSize: state.pageSize || 10,
          });
        }

        if (this.state.shouldExport) {
          exportToXls(resdata, "task_construction_rodillo");
          this.setState({
            shouldExport: false,
            loading: false,
            page: state.page,
            pageSize: state.pageSize || 100,
          });
        }
      });
  }

  viewTask(task) {
    Feathers.service("construccion")
      .get(task.id)
      .then((task) => this.setState({ task }));
  }

  initTask(task) {
    swal({
      title: "¿Iniciar tarea?",
      type: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((res) => {
      if (res.value) {
        Feathers.service(SERVICE)
          .patch(task.id, {
            hora_inicio: moment().format("YYYY-MM-DD HH:mm:ss"),
            status_tarea_id: 1,
            usuario_id: this.props.user.id,
          })
          .then((res) => {
            Feathers.service(SERVICE)
              .get(res.id)
              .then((task) => this.setState({ task }));
          });
      }
    });

    // swal("ver")
  }

  finishTask() {
    swal({
      title: "¿Finalizar tarea de construcción?",
      type: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((res) => {
      if (res.value) {
        Feathers.service(SERVICE)
          .patch(this.state.task.id, {
            hora_fin: moment().format("YYYY-MM-DD HH:mm:ss"),
            status_tarea_id: 2,
          })
          .then(async (res) => {
            const checkNotification = await Feathers.service(
              "notification_tasks"
            ).get(4);
            if (checkNotification.activo) {
              Feathers.service("notificacion").create({
                mensaje: `Se ha finalizado una tarea de constucción rodillos #${this.state.task.id}`,
                perfil_id: "1",
                fecha_hora: moment().format("YYYY-MM-DD HH:mm:ss"),
                tipo: 4,
              });
            }
            this.setState({
              task: null,
            });
          });
      }
    });
  }

  cancelTaskByIncidence(incidencia_id) {
    Feathers.service("construccion")
      .patch(this.state.task.id, {
        incidencia_id,
        status_tarea_id: 3,
        hora_fin: moment().format("YYYY-MM-DD HH:mm:ss"),
      })
      .then((res) => {
        this.setState({ task: null });
        notify.show("Incidencia registrada", "success");
      });
  }

  updateTask() {
    Feathers.service(SERVICE)
      .get(this.state.task.id)
      .then((task) => this.setState({ task }));
  }

  renderActions = (row) => {
    row = row.original;
    return (
      <>
        <Button
          onClick={() => this.viewTask(row)}
          icon="play"
          basic
          size="mini"
          color="blue"
          content="Ver"
        />
        <Button
          onClick={() => this.openModal(row)}
          icon="eye"
          size="mini"
          basic
          color="blue"
          content="Obser."
          style={{ backgroundColor: "#0098f0" }}
        />
      </>
    );
  };

  handleClienteResultSelect = async (e, { result }) => {
    this.setState({ cliente: result.nombre });

    this.setState(
      {
        filterOptions: {
          ...this.state.filterOptions,
          cliente_id: result.id,
        },
      },
      () => this.fetchData(this.state)
    );
  };

  handleMaterialResultSelect = async (e, { result }) => {
    this.setState({ material: result.nombre });

    this.setState(
      {
        filterOptions: {
          ...this.state.filterOptions,
          material_id: result.id,
        },
      },
      () => this.fetchData(this.state)
    );
  };

  handleClienteSearchChange = (e, { value }) => {
    this.setState({ clienteLoading: true, cliente: value });

    this.timeout = setTimeout(() => {
      if (this.state.cliente.length < 1) return this.resetClienteComponent();

      Feathers.service("cliente")
        .find({
          query: {
            nombre: { $like: `%${value}%` },
            activo: 1,
            $limit: 3,
          },
        })
        .then((res) => {
          this.setState({
            clienteLoading: false,
            clienteResults: res.data,
          });
        });
    }, 500);
  };

  handleMaterialSearchChange = (e, { value }) => {
    this.setState({ materialLoading: true, material: value });

    setTimeout(() => {
      if (this.state.material.length < 1) return this.resetMaterialComponent();

      Feathers.service("material")
        .find({
          query: {
            nombre: { $like: `%${value}%` },
            activo: 1,
            $limit: 3,
          },
        })
        .then((res) => {
          this.setState({
            materialLoading: false,
            materialResults: res.data,
          });
        });
    }, 500);
  };

  onPartidaChange = (partida) => {
    this.setState(
      {
        filterValues: {
          ...this.state.filterValues,
          partidaValue: partida,
        },
      },
      () => this.fetchData(this.state)
    );
  };

  onChangeRodillo = (rodillo) => {
    this.setState(
      {
        filterValues: {
          ...this.state.filterValues,
          rodilloValue: rodillo,
        },
      },
      () => this.fetchData(this.state)
    );
  };

  onChangePedido = (pedido) => {
    this.setState(
      {
        filterValues: {
          ...this.state.filterValues,
          pedidoValue: pedido,
        },
      },
      () => this.fetchData(this.state)
    );
  };

  onDateChange = (date) => {
    const dateFormat = moment(date).format("YYYY-MM-DD");
    this.setState(
      {
        filterValues: {
          ...this.state.filterValues,
          fechaEntrega: dateFormat,
        },
      },
      () => this.fetchData(this.state)
    );
  };

  resetClienteComponent = () => {
    this.setState({ clienteLoading: false, clienteResults: [], cliente: "" });
  };

  resetMaterialComponent = () => {
    this.setState({
      materialLoading: false,
      materialResults: [],
      material: "",
    });
  };

  handleCleanButton = async () => {
    this.resetClienteComponent();
    this.resetMaterialComponent();
    this.setState(
      {
        filterValues: {
          rodilloValue: null,
          partidaValue: null,
          pedidoValue: null,
          fechaEntrega: null,
        },
        filterOptions: {
          cliente_id: null,
          material_id: null,
          ref_sae: null,
          pedido_id: null,
          partida_id: null,
        },
      },
      () => {
        this.fetchData(this.state);
      }
    );
  };

  renderList() {
    return (
      <div>
        <Modal
          open={this.state.modalOpen}
          onClose={this.handleClose}
          size="small"
        >
          <Header icon="browser" content="Rodillo" />
          <Modal.Content>
            <Container fluid textAlign="center">
              <img src={this.state.imageToOpen} />
            </Container>
          </Modal.Content>
          <Modal.Actions>
            <Button color="green" onClick={this.handleClose} inverted>
              <Icon name="checkmark" /> Ok
            </Button>
          </Modal.Actions>
        </Modal>

        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Header
                as="h2"
                icon="building"
                content="Construcción - Rodillos"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Form>
                <Form.Group>
                  <TasksFilter
                    loading={this.state.loading}
                    clienteLoading={this.state.clienteLoading}
                    filters={this.state.filters}
                    costumer={this.state.cliente || ""}
                    costumers={this.state.clienteResults}
                    onSelectCostumer={this.handleClienteResultSelect}
                    onSearchCostumerChange={this.handleClienteSearchChange}
                    costumerResultRenderer={renderClientSearchResults}
                    partidaValue={this.state.filterValues.partidaValue}
                    onChangePartida={this.onPartidaChange}
                    rodilloValue={this.state.filterValues.rodilloValue}
                    onChangeRodillo={this.onChangeRodillo}
                    fechaEntrega={this.state.filterValues.fechaEntrega}
                    onDateChange={this.onDateChange}
                    materialLoading={this.state.materialLoading}
                    materials={this.state.materialResults}
                    material={this.state.material ? this.state.material : ""}
                    handleMaterialResultSelect={this.handleMaterialResultSelect}
                    handleMaterialSearchChange={this.handleMaterialSearchChange}
                    renderMaterialSearchResults={renderMaterialSearchResults}
                    pedidoValue={this.state.filterValues.pedidoValue || ""}
                    onPedidoChange={this.onChangePedido}
                  />
                  <Form.Field>
                    <label>Limpiar</label>
                    <Button
                      basic
                      color="red"
                      circular
                      icon="x"
                      onClick={this.handleCleanButton}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Exportar</label>
                    <Button
                      basic
                      color="green"
                      circular
                      icon="file excel outline"
                      onClick={() => {
                        this.setState({ shouldExport: true }, () => {
                          this.fetchData(this.state);
                        });
                      }}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <ReactTable
                columns={this.state.tableColumns}
                manual
                data={this.state.tasks}
                loading={this.state.loading}
                pages={Math.ceil(this.state.total / this.state.pageSize)}
                onFetchData={this.fetchData}
                defaultPageSize={100}
                style={{ textAlign: "center" }}
                resizable={false}
                onSortedChange={async (newSorted, column, shiftKey) => {
                  let direction;
                  if (column.Header == this.state.columna.Header) {
                    direction = this.state.sortDirection ? false : true;
                  } else {
                    direction = false;
                  }

                  this.setState(
                    {
                      columna: column,
                      sortDirection: direction,
                    },
                    () => {
                      console.log(this.state);
                    }
                  );
                  this.fetchData();
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <ObservationModal
          data={this.state.currentRow}
          isOpened={this.state.modalOpened}
          onClose={this.closeModal}
        />
      </div>
    );
  }

  render() {
    if (this.state.ready) {
      if (this.state.task) {
        //goBack={this.goBack} initTask={this.initTask} finishTask={this.finishTask} task={this.state.task} cancelTaskByIncidence={this.cancelTaskByIncidence}/
        return (
          <TaskContainer
            goBack={this.goBack}
            initTask={this.initTask}
            finishTask={this.finishTask}
            task={this.state.task}
            updateTask={this.updateTask}
            cancelTaskByIncidence={this.cancelTaskByIncidence}
          />
        );
      }

      return this.renderList();
    }

    return <LoaderData />;
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(ConstruccionRodilloContainer);
