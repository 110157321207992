import React, { Component } from "react";
import ReactTable from "react-table";
import {
  Button,
  Grid,
  Icon,
  Progress,
  Header,
  Dropdown,
  Form,
  Search,
  Label,
} from "semantic-ui-react";
import Feathers from "../redux/FeathersRedux";
import "react-table/react-table.css";
import { exportToXls } from "../utils/Utils";
import Toggle from "react-toggle";
import DayPickerInput from "react-day-picker/DayPickerInput";
import moment from "moment";
import "moment/locale/es";
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";
import ReportFilter from "../components/FIlters/ReportFilter";
import { laravelService } from "../utils/laravelService";

const renderSearchSaeResults = ({ id, ref_sae }) => (
  <Label key={id} content={ref_sae} />
);

export default class CaptureReportContainer extends Component {
  /* #region state */

  state = {
    captures: [],
    total: 0,
    page: 0,
    loading: false,
    pageSize: 25,
    filter: {
      initDay: "",
      lastDay: "",
      cliente_id: null,
      rodilloValue: "",
      partidaValue: "",
      pedidoValue: "",
      statusValue: "",
      producto_id: null,
    },
    $sort: {
      $saeSortDirection: null,
    },
    // filters_options

    producto_options: [],

    // search bar objects

    sae: null,

    // search bar results
    saeResults: null,

    // search bar loading,
    saeLoading: null,
    columna: "pedido_id",
    sortDirection: false,
    showSae: false,
    filters: ["rodillo", "partida", "range", "product"],
    tableColumns: [
      { Header: "OT", accessor: "pedido_id" },
      { Header: "SAE", accessor: "ref_sae" },
      // { Header: "#Unidad", accessor: "id" },
      {
        Header: "Rodillo",
        id: "rodillo_id",
        accessor: (d) => {
          if (d.rodillo_id) return "#" + d.rodillo_id;
          if (!d.rodillo_id) return;
        },
      },
      {
        Header: "Partida",
        headerStyle: { fontSize: 16 },
        id: "partida_id",
        accessor: (d) => {
          if (d.partida_id) return d.partida_id;
          return "---";
        },
      },
      { Header: "Producto", accessor: "productoNombre" },
      {
        Header: "Molino",
        id: "molino",
        accessor: (d) => {
          if (d.molino_dureza_de_tapones)
            return "Dureza de Tapones: " + d.molino_dureza_de_tapones;
          return "---";
        },
      },
      {
        Header: "Desviste",
        id: "desviste",
        accessor: (d) => {
          let cellValue = "";

          if (d.dn_final_desviste)
            cellValue += " DN Final: " + d.dn_final_desviste;

          if (cellValue.length === 0) return "---";

          return cellValue;
        },
      },

      {
        Header: "Contrucción",
        id: "contruccion",
        accessor: (d) => {
          let cellValue = "";

          if (d.de_final_construccion)
            cellValue += " DE Final: " + d.de_final_construccion;
          if (d.dn_final_construccion)
            cellValue += " DN Final: " + d.dn_final_construccion;

          if (cellValue.length === 0) return "---";

          return cellValue;
        },
      },

      {
        Header: "Desbaste",
        id: "desbaste",
        accessor: (d) => {
          let cellValue = "";

          if (d.de_inicial_desbaste)
            cellValue += " DE Inicial: " + d.de_inicial_desbaste;
          if (d.de_final_desbaste)
            cellValue += " DE Final: " + d.de_final_desbaste;
          if (d.lc_final_desbaste)
            cellValue += " LC Final: " + d.lc_final_desbaste;

          if (cellValue.length === 0) return "---";

          return cellValue;
        },
      },

      {
        Header: "Rectificado",
        id: "rectificado",
        accessor: (d) => {
          let cellValue = "";

          if (d.de_final_rectificado)
            cellValue += " DE Finial: " + d.de_final_rectificado;
          if (d.dureza_rectificado)
            cellValue += " Dureza: " + d.dureza_rectificado;
          if (d.lc_final_rectificado)
            cellValue += " LC Final: " + d.lc_final_rectificado;

          if (cellValue.length === 0) return "---";

          return cellValue;
        },
      },

      {
        Header: "Corte",
        id: "corte",
        accessor: (d) => {
          let cellValue = "";

          if (d.peso_antes_corte)
            cellValue += " Peso antes: " + d.peso_antes_corte;
          if (d.peso_despues_corte)
            cellValue += " Peso después: " + d.peso_despues_corte;

          if (cellValue.length === 0) return "---";

          return cellValue;
        },
      },

      // {
      //   Header: "Control de Calidad",
      //   id: "controldecalidad",
      //   accessor: (d) => {
      //     let cellValue = "";

      //     if (d.altura_control_calidad)
      //       cellValue += "Altura: " + d.altura_control_calidad;
      //     if (d.de_control_calidad) cellValue += " DE: " + d.de_control_calidad;
      //     if (d.dn_control_calidad) cellValue += " DN: " + d.dn_control_calidad;
      //     if (d.espesor_control_calidad)
      //       cellValue += " Espesor: " + d.espesor_control_calidad;
      //     if (d.lc_control_calidad) cellValue += " LC: " + d.lc_control_calidad;
      //     if (d.lt_control_calidad) cellValue += " LT: " + d.lt_control_calidad;
      //     if (d.perimetro_control_calidad)
      //       cellValue += " Perímetro: " + d.perimetro_control_calidad;
      //     if (d.peso_control_calidad)
      //       cellValue += " Peso: " + d.peso_control_calidad;

      //     if (cellValue.length === 0) return "---";

      //     return cellValue;
      //   },
      // },
    ],
  };

  /* #endregion state */

  /* #region lifecycle */
  componentDidMount() {
    this.fetchFiltersOptions();
  }

  handleSaeToggle = () => {
    this.setState(
      {
        showSae: !this.state.showSae,
      },
      () => {
        console.log(this.state);
      }
    );
    console.log(this.state.showSae);
  };

  fetchDropdownOptions = async (_) => {
    let producto_options = await Feathers.service("producto").find({
      query: {
        $limit: "-1",
      },
    });

    let formated_producto_options = producto_options.map((v) => {
      return {
        key: v.id,
        value: v.id,
        text: v.nombre,
      };
    });

    this.setState({
      producto_options: formated_producto_options,
    });
  };

  handleSort = (array) => {
    let column = this.state.columna;
    switch (column.Header) {
      case "#Unidad":
        array.data.sort((a, b) => {
          return a.id - b.id;
        });
        break;
      case "REF SAE":
        array.data.sort((a, b) => {
          return a.ref_sae - b.ref_sae;
        });
        break;
      case "Producto":
        array.data.sort((a, b) => {
          const nameA = a.productoNombre.toUpperCase(); // ignore upper and lowercase
          const nameB = b.productoNombre.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Molino":
        array.data.sort((a, b) => {
          const nameA = a.molino_dureza_de_tapones; // ignore upper and lowercase
          const nameB = b.molino_dureza_de_tapones; // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Desviste":
        array.data.sort((a, b) => {
          const nameA = a.dn_final_desviste; // ignore upper and lowercase
          const nameB = b.dn_final_desviste; // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Construcción":
        array.data.sort((a, b) => {
          const nameA = a.de_final_construccion.toUpperCase(); // ignore upper and lowercase
          const nameB = b.de_final_construccion.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Desbaste":
        array.data.sort((a, b) => {
          const nameA = a.de_inicial_desbaste; // ignore upper and lowercase
          const nameB = b.de_inicial_desbaste; // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Rectificado":
        array.data.sort((a, b) => {
          const nameA = a.de_final_rectificado; // ignore upper and lowercase
          const nameB = b.de_final_rectificado; // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Corte":
        array.data.sort((a, b) => {
          const nameA = a.peso_antes_corte; // ignore upper and lowercase
          const nameB = b.peso_antes_corte; // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Control de Calidad":
        array.data.sort((a, b) => {
          const nameA = a.altura_control_calidad; // ignore upper and lowercase
          const nameB = b.altura_control_calidad; // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      default:
        break;
    }
    if (this.state.sortDirection) {
      array.data.reverse();
    }
    return array;
  };
  /* #endregion lifecycle */

  /* #region searchFunctions */
  handleResultSaeSelect = async (e, { result }) => {
    this.setState({ sae: result.ref_sae });

    await this.setState({
      filter: {
        ...this.state.filter,
        ref_sae: result.ref_sae,
      },
    });

    //this.fetchData(this.state);
  };

  handleSearchSaeChange = (e, { value }) => {
    this.setState({ saeLoading: true, sae: value });

    setTimeout(() => {
      if (this.state.sae.length < 1) return this.resetSaeComponent();

      Feathers.service("pedido")
        .find({
          query: {
            ref_sae: { $like: `%${value}%` },
            //$limit: 5
          },
        })
        .then((res) => {
          this.setState({
            saeLoading: false,
            saeResults: res.data,
          });
        });
    }, 500);
  };

  resetSaeComponent = () =>
    this.setState({ saeLoading: false, saeResults: [], sae: "" });

  /* #endregion searchFunctions */

  /* #region fetchingData */
  fetchData = async (state = {}) => {
    this.setState({ loading: true }, async () => {
      let query = {};

      if (this.state.pageSize) {
        query["page_size"] = this.state.pageSize;
      }

      if (this.state.columna && this.state.sortDirection !== null) {
        query["sort_column"] = this.state.columna;
        query["sort_direction"] = this.state.sortDirection ? "ASC" : "DESC";
      }

      if (this.state.filter.rodilloValue) {
        query["rodillo_id"] = this.state.filter.rodilloValue;
      }

      if (this.state.filter.pedidoValue) {
        query["pedido_id"] = this.state.filter.pedidoValue;
      }

      if (this.state.filter.partidaValue) {
        query["partida_id"] = this.state.filter.partidaValue;
      }

      if (this.state.filter.statusValue) {
        query["status_id"] = this.state.filter.statusValue;
      }

      if (this.state.filter.cliente_id) {
        query["cliente_id"] = this.state.filter.cliente_id;
      }

      if (this.state.filter.producto_id) {
        query["producto_id"] = this.state.filter.producto_id;
      }

      if (this.state.filter.initDay && this.state.filter.lastDay) {
        query["startDate"] = moment(this.state.filter.initDay).format(
          "YYYY-MM-DD hh:mm"
        );
        query["endDate"] = moment(this.state.filter.lastDay)
          .add(23, "hours")
          .format("YYYY-MM-DD hh:mm");
      }

      await laravelService
        .get(`reports/captures?page=${this.state.page + 1}`, {
          params: query,
        })
        .then((res) => {
          this.setState({
            captures: res.data.data,
            total: res.data.total,
            loading: false,
            page: state.page,
            pageSize: this.state.pageSize || 10,
          });
        });
    });
  };

  fetchFiltersOptions = async (_) => {
    let productos = await Feathers.service("producto").find({
      query: {
        $limit: "-1",
      },
    });

    let producto_options = productos.map((v) => {
      return {
        key: v.id,
        text: v.nombre,
        value: v.id,
      };
    });

    this.setState({
      producto_options,
    });
  };

  /* #endregion fetchingData */

  handleFilterSubmit = (_) => {
    this.fetchData(this.state);
  };

  handleCleanButton = (_) => {
    this.resetSaeComponent();
    this.setState(
      {
        filter: {
          initDay: "",
          lastDay: "",
          cliente_id: null,
          rodilloValue: "",
          partidaValue: "",
          pedidoValue: "",
          statusValue: "",
          producto_id: null,
        },
      },
      (_) => this.fetchData(this.state)
    );
  };

  changeInitDay = (date) => {
    this.setState({
      filter: {
        ...this.state.filter,
        initDay: date,
      },
    });
  };

  changeLastDay = (date) => {
    this.setState({
      filter: {
        ...this.state.filter,
        lastDay: date,
      },
    });
  };

  onRodilloChange = (rodillo) => {
    this.setState({
      filter: {
        ...this.state.filter,
        rodilloValue: rodillo,
      },
    });
  };

  onPartidaChange = (partida) => {
    this.setState({
      filter: {
        ...this.state.filter,
        partidaValue: partida,
      },
    });
  };

  changeProduct = (d) => {
    this.setState({
      filter: {
        ...this.state.filter,
        producto_id: d,
      },
    });
  };

  /* #region render */
  render() {
    return (
      <div>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Header
                as="h2"
                icon="pencil square"
                content="Reporte de Captura"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form size="large">
                <Form.Group>
                  <ReportFilter
                    loading={this.state.loading}
                    filters={this.state.filters}
                    initDay={this.state.filter.initDay}
                    lastDay={this.state.filter.lastDay}
                    changeInitDay={this.changeInitDay}
                    changeLastDay={this.changeLastDay}
                    rodilloValue={this.state.filter.rodilloValue || ""}
                    onRodilloChange={this.onRodilloChange}
                    partidaValue={this.state.filter.partidaValue || ""}
                    onChangePartida={this.onPartidaChange}
                    onProductChange={this.changeProduct}
                    products={this.state.producto_options}
                    productValue={this.state.filter.producto_id || ""}
                  />
                  {/* <Form.Field>
                    <label>SAE</label>
                    <Search
                      icon=""
                      loading={this.state.saeLoading}
                      value={this.state.sae}
                      results={this.state.saeResults}
                      onResultSelect={this.handleResultSaeSelect}
                      onSearchChange={this.handleSearchSaeChange}
                      resultRenderer={renderSearchSaeResults}
                      placeholder="SAE"
                      className="full-width"
                    />
                  </Form.Field> */}
                  {/* <Form.Field>
                    <label>Producto</label>
                    <Form.Dropdown
                      selection
                      options={this.state.producto_options}
                      onChange={(e, d) => {
                        this.setState({
                          filter: {
                            ...this.state.filter,
                            producto_id: d.value,
                          },
                        });
                      }}
                      value={this.state.filter.producto_id}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Fecha Inicio</label>
                    <DayPickerInput
                      value={this.state.filter.startDate}
                      format="YYYY-MM-DD"
                      formatDate={formatDate}
                      parseDate={parseDate}
                      onDayChange={(date) => {
                        this.setState(
                          {
                            filter: {
                              ...this.state.filter,
                              startDate: moment(date).format(
                                "YYYY-MM-DD hh:mm:ss"
                              ),
                            },
                          },
                          (_) => {
                            if (
                              this.state.filter.startDate &&
                              this.state.filter.endDate
                            ) {
                              this.fetchData(this.state);
                            }
                          }
                        );
                      }}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Fecha Fin</label>
                    <DayPickerInput
                      value={this.state.filter.endDate}
                      format="YYYY-MM-DD"
                      formatDate={formatDate}
                      parseDate={parseDate}
                      onDayChange={(date) => {
                        console.log("----", date);
                        this.setState(
                          {
                            filter: {
                              ...this.state.filter,
                              endDate: moment(date).format(
                                "YYYY-MM-DD hh:mm:ss"
                              ),
                            },
                          },
                          (_) => {
                            if (
                              this.state.filter.startDate &&
                              this.state.filter.endDate
                            ) {
                              this.fetchData(this.state);
                            }
                          }
                        );
                      }}
                    />
                  </Form.Field> */}
                  <Form.Field>
                    <label>Filtrar</label>
                    <Button
                      circular
                      basic
                      icon="filter"
                      onClick={this.handleFilterSubmit}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Limpiar</label>
                    <Button
                      circular
                      basic
                      color="red"
                      icon="x"
                      onClick={this.handleCleanButton}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Exportar</label>
                    <Button
                      circular
                      basic
                      color="green"
                      icon="file excel outline"
                      onClick={() => {
                        if (
                          this.state.filter.startDate &&
                          this.state.filter.endDate
                        ) {
                          let query = {
                            $limit: "ALL",
                            $skip: "0",
                            excel: true,
                            reportede: "captures",
                          };

                          query["startDate"] = this.state.filter.startDate;
                          query["endDate"] = this.state.filter.endDate;

                          this.setState({ loading: true }, () => {
                            exportToXls(
                              [
                                {
                                  startDate: this.state.filter.startDate,
                                  endDate: this.state.filter.endDate,
                                },
                              ],
                              "report_captures"
                            );
                            this.setState({
                              loading: false,
                            });
                          });
                        }

                        this.setState({ loading: true }, () => {
                          exportToXls(
                            [
                              {
                                startDate: this.state.filter.startDate,
                                endDate: this.state.filter.endDate,
                              },
                            ],
                            "report_captures"
                          );
                          this.setState({
                            loading: false,
                          });
                        });
                      }}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row /* columns={1} */>
            <Grid.Column>
              <ReactTable
                columns={this.state.tableColumns}
                manual
                data={this.state.captures}
                loading={this.state.loading}
                pages={Math.ceil(this.state.total / this.state.pageSize)}
                onFetchData={this.fetchData}
                defaultPageSize={25}
                style={{ textAlign: "center" }}
                pageSize={this.state.pageSize}
                page={this.state.page}
                pageIndex={this.state.page}
                resizable={false}
                onSortedChange={async (newSorted, column, shiftKey) => {
                  let direction;
                  if (newSorted[0].id == this.state.columna) {
                    direction = !this.state.sortDirection;
                  } else {
                    console.log(this.state.sortDirection);
                    direction = !this.state.sortDirection;
                  }

                  this.setState(
                    {
                      columna: newSorted[0].id,
                      sortDirection: this.state.sortDirection ? false : true,
                    },
                    () => {
                      this.fetchData();
                    }
                  );
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }

  /* #endregion render */
}
