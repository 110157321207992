import React, { Component } from "react";
import Feathers from "../../redux/FeathersRedux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import TaskStatus from "../../components/Tasks/TaskStatus";
import {
  Button,
  Grid,
  Header,
  Search,
  Form,
  Label,
  Input,
} from "semantic-ui-react";
import swal from "sweetalert2";
import moment from "moment";
import TaskContainer from "../../components/Tasks/Undress/UndressTask";
//import Timer from '../../components/Timer'
import { notify } from "react-notify-toast";
import Config from "../../config/index";
import { connect } from "react-redux";
import { exportToXls } from "../../utils/Utils";
import LoaderData from "../LoaderData";
import ObservationModal from "../../components/ObservationModal";
import Toggle from "react-toggle";
import TasksFilter from "../../components/FIlters/TasksFilter";

const renderClientSearchResults = ({ id, nombre }) => (
  <Label key={id} content={nombre} />
);

const renderMaterialSearchResults = ({ id, nombre }) => (
  <Label key={id} content={nombre} />
);

class UndressContainer extends Component {
  timeout = null;

  constructor(props) {
    super(props);

    this.state = {
      tasks: [],
      total: 0,
      page: 0,
      loading: false,
      pageSize: 100,
      ///
      ready: false,
      task: null,
      modalOpen: false,
      imageToOpen: "",

      filters: [
        "pedido",
        "partida",
        "rodillo",
        "costumer",
        "material",
        "entrega",
      ],

      filterOptions: {
        cliente_id: null,
        material_id: null,
        ref_sae: null,
        pedido_id: null,
        partida_id: null,
      },

      filterValues: {
        rodilloValue: null,
        partidaValue: null,
        pedidoValue: null,
        fechaEntrega: null,
      },

      cliente: null,
      material: null,
      sae: null,

      clienteLoading: null,
      materialLoading: null,
      saeLoading: null,
      shouldExport: false,

      clienteResults: null,
      materialResults: null,
      saeResults: null,
      interval: undefined,

      modalOpened: false,
      currentRow: {},
      columna: "",
      sortDirection: false,
      showSae: false,
      tableColumns: [
        /* { Header: "SAE", headerStyle: {fontSize: 20 }, Cell: row => {
            if(row.original.rodillo.partida.pedido.ref_sae) return <span>{row.original.rodillo.partida.pedido.ref_sae}</span>
            return "---"                                   
        }}, */
        {
          Header: "OT",
          maxWidth: 80,
          headerStyle: { fontSize: 16 },
          id: "ot",
          accessor: (d) => {
            if (d.rodillo.partida.pedido.id) return d.rodillo.partida.pedido.id;
            return "---";
          },
        },
        {
          Header: "SAE",
          maxWidth: 80,
          headerStyle: { fontSize: 16 },
          id: "ref_sae",
          accessor: (d) => {
            if (d.rodillo.partida.pedido.ref_sae)
              return d.rodillo.partida.pedido.ref_sae;
            return "---";
          },
        },
        {
          Header: "Partida",
          headerStyle: { fontSize: 16 },
          id: "partida_id",
          accessor: (d) => {
            if (d.rodillo)
              return d.rodillo.partida_id ? d.rodillo.partida_id : "---";
            return "---";
          },
        },
        {
          Header: "Rodillo",
          headerStyle: { fontSize: 16 },
          id: "rodillo_id",
          accessor: (d) => {
            if (d.rodillo_id) return d.rodillo_id;
            return "---";
          },
        },
        {
          Header: "Cliente",
          headerStyle: { fontSize: 16 },
          Cell: (row) => {
            return (
              <span>
                {row.original.rodillo
                  ? row.original.rodillo.partida.pedido.cliente.nombre
                  : "---"}
              </span>
            );
          },
        },
        {
          Header: "Material",
          headerStyle: { fontSize: 16 },
          Cell: (row) => {
            return (
              <span>
                {row.original.rodillo
                  ? row.original.rodillo.partida.material.nombre
                  : "---"}
              </span>
            );
          },
        },
        {
          Header: "Fecha Ent.",
          headerStyle: { fontSize: 16 },
          Cell: (row) => {
            if (row.original.rodillo) {
              return row.original.rodillo.partida.pedido.fecha_entrega
                ? moment(
                    row.original.rodillo.partida.pedido.fecha_entrega
                  ).format("DD-MM-YYYY")
                : "N/A";
            } else {
              return <p>N/A</p>;
            }
          },
        },
        {
          Header: "Dureza",
          maxWidth: 80,
          headerStyle: { fontSize: 16 },
          Cell: (row) => {
            return (
              <span>
                {row.original.rodillo
                  ? row.original.rodillo.partida.dureza.valor
                  : "---"}
              </span>
            );
          },
        },
        {
          Header: "DN",
          maxWidth: 60,
          headerStyle: { fontSize: 16 },
          Cell: (row) => {
            return (
              <span>
                {row.original.rodillo ? row.original.rodillo.partida.dn : "---"}
              </span>
            );
          },
        },
        {
          Header: "DE",
          maxWidth: 60,
          headerStyle: { fontSize: 16 },
          Cell: (row) => {
            return (
              <span>
                {row.original.rodillo ? row.original.rodillo.partida.de : "---"}
              </span>
            );
          },
        },
        {
          Header: "LC",
          maxWidth: 60,
          headerStyle: { fontSize: 16 },
          Cell: (row) => {
            return (
              <span>
                {row.original.rodillo ? row.original.rodillo.partida.lc : "---"}
              </span>
            );
          },
        },
        {
          Header: "LT",
          maxWidth: 60,
          headerStyle: { fontSize: 16 },
          Cell: (row) => {
            return (
              <span>
                {row.original.rodillo ? row.original.rodillo.partida.lt : "---"}
              </span>
            );
          },
        },
        {
          Header: "Kilos",
          maxWidth: 80,
          headerStyle: { fontSize: 16 },
          Cell: (row) => {
            return (
              <span>
                {row.original.rodillo
                  ? row.original.rodillo.partida.kilos
                  : "---"}
              </span>
            );
          },
        },
        {
          Header: "Cant.",
          maxWidth: 80,
          headerStyle: { fontSize: 16 },
          Cell: (row) => {
            return (
              <span>
                {row.original.rodillo
                  ? row.original.rodillo.partida.cantidad
                  : "---"}
              </span>
            );
          },
        },
        {
          Header: "Acciones",
          sortable: false,
          headerStyle: { fontSize: 16 },
          Cell: this.renderActions,
        },
      ],
    };

    this.renderList = this.renderList.bind(this);
    this.fetchData = this.fetchData.bind(this);
    // this.renderActions = this.renderActions.bind(this);
    this.checkActiveTasks = this.checkActiveTasks.bind(this);
    this.finishTask = this.finishTask.bind(this);
    this.initTask = this.initTask.bind(this);
    this.cancelTaskByIncidence = this.cancelTaskByIncidence.bind(this);
    this.viewTask = this.viewTask.bind(this);
    this.goBack = this.goBack.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.updateTask = this.updateTask.bind(this);
    this.handleSaeToggle = this.handleSaeToggle.bind(this);
  }

  openModal = (row) => {
    this.setState({
      modalOpened: true,
      currentRow: row.rodillo.partida.observaciones,
    });
  };

  closeModal = () => {
    this.setState({
      modalOpened: false,
    });
  };

  handleClienteResultSelect = async (e, { result }) => {
    this.setState({ cliente: result.nombre });

    this.setState(
      {
        filterOptions: {
          ...this.state.filterOptions,
          cliente_id: result.id,
        },
      },
      () => this.fetchData(this.state)
    );
  };

  handleMaterialResultSelect = async (e, { result }) => {
    this.setState({ material: result.nombre });

    await this.setState(
      {
        filterOptions: {
          ...this.state.filterOptions,
          material_id: result.id,
        },
      },
      () => this.fetchData(this.state)
    );
  };

  handleClienteSearchChange = (e, { value }) => {
    this.setState({ clienteLoading: true, cliente: value });

    this.timeout = setTimeout(() => {
      if (this.state.cliente.length < 1) return this.resetClienteComponent();

      Feathers.service("cliente")
        .find({
          query: {
            nombre: { $like: `%${value}%` },
            activo: 1,
            $limit: 3,
          },
        })
        .then((res) => {
          this.setState({
            clienteLoading: false,
            clienteResults: res.data,
          });
        });
    }, 500);
  };

  handleMaterialSearchChange = (e, { value }) => {
    this.setState({ materialLoading: true, material: value });

    setTimeout(() => {
      if (this.state.material.length < 1) return this.resetMaterialComponent();

      Feathers.service("material")
        .find({
          query: {
            nombre: { $like: `%${value}%` },
            activo: 1,
            $limit: 3,
          },
        })
        .then((res) => {
          this.setState({
            materialLoading: false,
            materialResults: res.data,
          });
        });
    }, 500);
  };

  resetClienteComponent = () => {
    this.setState({ clienteLoading: false, clienteResults: [], cliente: "" });
  };

  resetMaterialComponent = () => {
    this.setState({
      materialLoading: false,
      materialResults: [],
      material: "",
    });
  };

  updateTask() {
    Feathers.service("desviste")
      .get(this.state.task.id)
      .then((task) => this.setState({ task }));
  }

  handleOpen = (logicImageUrl) => {
    var imageToOpen = Config.apiUrl + logicImageUrl;
    this.setState({ modalOpen: true, imageToOpen: imageToOpen });
  };

  handleClose = () => {
    this.setState({ modalOpen: false });
  };

  componentDidMount() {
    this.checkActiveTasks();
  }

  goBack() {
    this.setState({ task: null });
  }

  checkActiveTasks() {
    Feathers.service("desviste")
      .find({
        query: {
          usuario_id: this.props.user.id,
          status_tarea_id: 1,
          $table: true,
        },
      })
      .then((res) => {
        if (res.total > 0) {
          this.setState({
            task: res.data[0],
            ready: true,
          });
        } else {
          this.setState({
            ready: true,
          });
        }
      });
  }

  handleSort = (array) => {
    let column = this.state.columna;
    switch (column.Header) {
      case "SAE":
        array.data.sort((a, b) => {
          return (
            a.rodillo.partida.pedido.ref_sae - b.rodillo.partida.pedido.ref_sae
          );
        });
        break;
      case "Cliente":
        array.data.sort((a, b) => {
          const nameA = a.rodillo.partida.pedido.cliente.nombre.toUpperCase(); // ignore upper and lowercase
          const nameB = b.rodillo.partida.pedido.cliente.nombre.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "#Unidad":
        array.data.sort((a, b) => {
          let A = a.rodillo.id;
          let B = b.rodillo.id;
          return A - B;
        });
        break;
      case "Fecha Ent.":
        array.data.sort((a, b) => {
          const nameA = a.rodillo.partida.pedido.fecha_entrega.toUpperCase(); // ignore upper and lowercase
          const nameB = b.rodillo.partida.pedido.fecha_entrega.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Material":
        array.data.sort((a, b) => {
          let nameA = a.rodillo.partida.material.nombre.toUpperCase();
          let nameB = b.rodillo.partida.material.nombre.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Dureza":
        array.data.sort((a, b) => {
          let A = a.rodillo.partida.dureza.valor;
          let B = b.rodillo.partida.dureza.valor;
          return A - B;
        });
        break;
      case "DN":
        array.data.sort((a, b) => {
          let A = a.rodillo.partida.dn;
          let B = b.rodillo.partida.dn;
          return A - B;
        });
        break;
      case "DE":
        array.data.sort((a, b) => {
          let A = a.rodillo.partida.de;
          let B = b.rodillo.partida.de;
          return A - B;
        });
        break;
      case "LC":
        array.data.sort((a, b) => {
          let A = a.rodillo.partida.lc;
          let B = b.rodillo.partida.lc;
          return A - B;
        });
        break;
      case "LT":
        array.data.sort((a, b) => {
          let A = a.rodillo.partida.lt;
          let B = b.rodillo.partida.lt;
          return A - B;
        });
        break;
      case "Kilos":
        array.data.sort((a, b) => {
          let A = a.rodillo.partida.kilos;
          let B = b.rodillo.partida.kilos;
          return A - B;
        });
        break;
      case "Cantidad":
        array.data.sort((a, b) => {
          let A = a.rodillo.partida.cantidad;
          let B = b.rodillo.partida.cantidad;
          return A - B;
        });
        break;
      default:
        break;
    }
    if (this.state.sortDirection) {
      array.data.reverse();
    }
    return array;
  };

  fetchData = async (state = {}) => {
    this.setState({
      loading: true,
    });
    let pageSize = state.pageSize || 10;

    let query = {
      $limit: this.state.shouldExport ? "-1" : pageSize,
      $skip: pageSize * state.page,
      $sort: {
        id: -1,
      },
      $table: true,
      $or: [{ status_tarea_id: null }, { status_tarea_id: 1 }],
      incidencia_id: null,
    };

    if (this.state.filterOptions.material_id !== null) {
      let partidas_de_cliente = await Feathers.service("partida").find({
        query: {
          $limit: "-1",
          material_id: this.state.filterOptions.material_id,
        },
      });

      let rodillos_del_cliente_con_las_partidas_validas = await Feathers.service(
        "rodillo"
      ).find({
        query: {
          $limit: "-1",
          partida_id: {
            $in: partidas_de_cliente.map((v) => v.id),
          },
        },
      });

      let los_id_de_los_rodillos_del_cliente = rodillos_del_cliente_con_las_partidas_validas.map(
        (v) => v.id
      );

      query["rodillo_id"] = {
        $in: los_id_de_los_rodillos_del_cliente,
      };
    }

    if (this.state.filterOptions.cliente_id !== null) {
      let pedidos_del_cliente = await Feathers.service("pedido").find({
        query: {
          $limit: "-1",
          cliente_id: this.state.filterOptions.cliente_id,
        },
      });

      let pedidos_ids = pedidos_del_cliente.map((v) => v.id);

      let partidas_de_los_pedidos = await Feathers.service("partida").find({
        query: {
          $limit: "-1",
          pedido_id: {
            $in: pedidos_ids,
          },
        },
      });

      let partidas_ids = partidas_de_los_pedidos.map((v) => v.id);

      let rodillos_de_las_partidas = await Feathers.service("rodillo").find({
        query: {
          $limit: "-1",
          partida_id: {
            $in: partidas_ids,
          },
        },
      });

      let rodillos_ids = rodillos_de_las_partidas.map((v) => v.id);

      query["rodillo_id"] = {
        $in: rodillos_ids,
      };
    }

    if (this.state.filterValues.pedidoValue) {
      let pedidos_id = await Feathers.service("pedido").find({
        query: {
          $limit: "-1",
          id: this.state.filterValues.pedidoValue,
        },
      });

      let pedidos_ids = pedidos_id.map((v) => v.id);

      let partidas_de_los_pedidos = await Feathers.service("partida").find({
        query: {
          $limit: "-1",
          pedido_id: {
            $in: pedidos_ids,
          },
        },
      });

      let partidas_ids = partidas_de_los_pedidos.map((v) => v.id);

      let rodillos_de_las_partidas = await Feathers.service("rodillo").find({
        query: {
          $limit: "-1",
          partida_id: {
            $in: partidas_ids,
          },
        },
      });

      let rodillos_ids = rodillos_de_las_partidas.map((v) => v.id);

      query["rodillo_id"] = {
        $in: rodillos_ids,
      };
    }

    if (this.state.filterValues.partidaValue) {
      let rodillos_de_las_partidas = await Feathers.service("rodillo").find({
        query: {
          $limit: "-1",
          partida_id: this.state.filterValues.partidaValue,
        },
      });

      let rodillos_ids = rodillos_de_las_partidas.map((v) => v.id);

      query["rodillo_id"] = {
        $in: rodillos_ids,
      };
    }

    if (this.state.filterValues.rodilloValue) {
      let rodillos_de_las_partidas = await Feathers.service("rodillo").find({
        query: {
          $limit: "-1",
          id: this.state.filterValues.rodilloValue,
        },
      });

      let rodillos_ids = rodillos_de_las_partidas.map((v) => v.id);

      query["rodillo_id"] = {
        $in: rodillos_ids,
      };
    }

    let rodillos_ids = [];

    if (this.state.filterValues.fechaEntrega) {
      let pedidos_id = await Feathers.service("pedido").find({
        query: {
          $limit: "-1",
          fecha_entrega: this.state.filterValues.fechaEntrega,
        },
      });

      let pedidos_ids = pedidos_id.map((v) => v.id);

      let partidas_de_los_pedidos = await Feathers.service("partida").find({
        query: {
          $limit: "-1",
          pedido_id: {
            $in: pedidos_ids,
          },
        },
      });

      let partidas_ids = partidas_de_los_pedidos.map((v) => v.id);

      let rodillos_de_las_partidas = await Feathers.service("rodillo").find({
        query: {
          $limit: "-1",
          partida_id: {
            $in: partidas_ids,
          },
        },
      });

      rodillos_ids = rodillos_de_las_partidas.map((v) => v.id);
    }

    if (this.state.shouldExport) {
      delete query.$table;
    }
    await Feathers.service("desviste")
      .find({
        query,
      })
      .then((res) => {
        res = this.handleSort(res);
        if (this.state.filterValues.fechaEntrega && res.data) {
          res.data = res.data.filter((task) =>
            rodillos_ids.includes(task.rodillo_id)
          );
        }

        if (res.data) {
          if (this.state.columna === "") {
            res.data.sort((a, b) => {
              const nameA = a.rodillo.partida.pedido.fecha_entrega.toUpperCase();
              const nameB = b.rodillo.partida.pedido.fecha_entrega.toUpperCase();

              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0;
            });
          }
        } else {
          if (this.state.columna === "") {
            res.sort((a, b) => {
              const nameA = a.rodillo.partida.pedido.fecha_entrega.toUpperCase();
              const nameB = b.rodillo.partida.pedido.fecha_entrega.toUpperCase();

              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0;
            });
          }
        }

        if (!this.state.shouldExport) {
          this.setState({
            tasks: res.data,
            total: res.total,
            loading: false,
            page: state.page,
            pageSize: state.pageSize || 100,
          });
        }

        if (this.state.shouldExport) {
          exportToXls(res, "task_undress");
          this.setState({
            shouldExport: false,
            loading: false,
            page: state.page,
            pageSize: state.pageSize || 100,
          });
        }
      });
  };

  viewTask(task) {
    Feathers.service("desviste")
      .get(task.id)
      .then((task) => this.setState({ task }));
  }

  async initTask(task) {
    return await swal({
      title: "¿Iniciar tarea?",
      type: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((res) => {
      if (res.value) {
        Feathers.service("desviste")
          .patch(task.id, {
            hora_inicio: moment().format("YYYY-MM-DD HH:mm:ss"),
            status_tarea_id: 1,
            usuario_id: this.props.user.id,
          })
          .then((res) => {
            Feathers.service("desviste")
              .get(res.id)
              .then((task) => this.setState({ task }));
          });
      }
    });
  }

  finishTask() {
    swal({
      title: "¿Finalizar tarea de desviste?",
      type: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((res) => {
      if (res.value) {
        Feathers.service("desviste")
          .patch(this.state.task.id, {
            hora_fin: moment().format("YYYY-MM-DD HH:mm:ss"),
            status_tarea_id: 2,
          })
          .then(async (res) => {
            const checkNotification = await Feathers.service(
              "notification_tasks"
            ).get(3);
            if (checkNotification.activo) {
              Feathers.service("notificacion").create({
                mensaje: `Se ha finalizado una tarea de desviste #${this.state.task.id}`,
                perfil_id: "1",
                fecha_hora: moment().format("YYYY-MM-DD HH:mm:ss"),
                tipo: 4,
              });
            }
            this.setState({
              task: null,
            });
          });
      }
    });
  }

  cancelTaskByIncidence(incidencia_id) {
    Feathers.service("desviste")
      .patch(this.state.task.id, {
        incidencia_id,
        status_tarea_id: 3,
        hora_fin: moment().format("YYYY-MM-DD HH:mm:ss"),
      })
      .then((res) => {
        this.setState({
          task: null,
        });
        notify.show("Incidencia registrada.", "success");
      });
  }

  renderActions = (row) => {
    row = row.original;

    return (
      <>
        <Button
          onClick={() => this.viewTask(row)}
          icon="play"
          size="mini"
          basic
          color="blue"
          content="Ver"
        />
        <Button
          onClick={() => this.openModal(row)}
          icon="eye"
          size="mini"
          basic
          color="blue"
          content="Obser."
          style={{ backgroundColor: "#0098f0" }}
        />
      </>
    );
  };

  handleCleanButton = () => {
    this.resetClienteComponent();
    this.resetMaterialComponent();
    this.setState(
      {
        filterValues: {
          rodilloValue: null,
          partidaValue: null,
          pedidoValue: null,
          fechaEntrega: null,
        },
        filterOptions: {
          cliente_id: null,
          material_id: null,
          ref_sae: null,
          // pedido_id: null,
          // partida_id: null,
        },
      },
      () => {
        this.fetchData(this.state);
      }
    );
  };

  handleSaeToggle = () => {
    this.setState({
      showSae: !this.state.showSae,
    });
  };

  onPartidaChange = (partida) => {
    this.setState(
      {
        filterValues: {
          ...this.state.filterValues,
          partidaValue: partida,
        },
      },
      () => this.fetchData(this.state)
    );
  };

  onChangeRodillo = (rodillo) => {
    this.setState(
      {
        filterValues: {
          ...this.state.filterValues,
          rodilloValue: rodillo,
        },
      },
      () => this.fetchData(this.state)
    );
  };

  onChangePedido = (pedido) => {
    this.setState(
      {
        filterValues: {
          ...this.state.filterValues,
          pedidoValue: pedido,
        },
      },
      () => this.fetchData(this.state)
    );
  };

  onDateChange = (date) => {
    const dateFormat = moment(date).format("YYYY-MM-DD");
    this.setState(
      {
        filterValues: {
          ...this.state.filterValues,
          fechaEntrega: dateFormat,
        },
      },
      () => this.fetchData(this.state)
    );
  };

  renderList() {
    return (
      <div>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Header as="h2" icon="doctor" content="Desviste" />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Form>
                <Form.Group>
                  <TasksFilter
                    loading={this.state.loading}
                    clienteLoading={this.state.clienteLoading}
                    filters={this.state.filters}
                    costumer={this.state.cliente || ""}
                    costumers={this.state.clienteResults}
                    onSelectCostumer={this.handleClienteResultSelect}
                    onSearchCostumerChange={this.handleClienteSearchChange}
                    costumerResultRenderer={renderClientSearchResults}
                    partidaValue={this.state.filterValues.partidaValue || ""}
                    onChangePartida={this.onPartidaChange}
                    rodilloValue={this.state.filterValues.rodilloValue || ""}
                    onChangeRodillo={this.onChangeRodillo}
                    fechaEntrega={this.state.filterValues.fechaEntrega || ""}
                    onDateChange={this.onDateChange}
                    materialLoading={this.state.materialLoading}
                    materials={this.state.materialResults}
                    material={this.state.material ? this.state.material : ""}
                    handleMaterialResultSelect={this.handleMaterialResultSelect}
                    handleMaterialSearchChange={this.handleMaterialSearchChange}
                    renderMaterialSearchResults={renderMaterialSearchResults}
                    pedidoValue={this.state.filterValues.pedidoValue || ""}
                    onPedidoChange={this.onChangePedido}
                  />
                  <Form.Field>
                    <label>Limpiar</label>
                    <Button
                      basic
                      color="red"
                      circular
                      icon="x"
                      onClick={this.handleCleanButton}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Exportar</label>
                    <Button
                      basic
                      color="green"
                      circular
                      icon="file excel outline"
                      onClick={() => {
                        exportToXls(this.state.tasks, "task_undress");
                      }}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <ReactTable
                columns={this.state.tableColumns}
                manual
                data={this.state.tasks}
                loading={this.state.loading}
                pages={Math.ceil(this.state.total / this.state.pageSize)}
                onFetchData={this.fetchData}
                defaultPageSize={100}
                style={{ textAlign: "center" }}
                onSortedChange={async (newSorted, column, shiftKey) => {
                  let direction;
                  if (column.Header == this.state.columna.Header) {
                    direction = this.state.sortDirection ? false : true;
                  } else {
                    direction = false;
                  }

                  this.setState(
                    {
                      columna: column,
                      sortDirection: direction,
                    },
                    () => {
                      console.log(this.state);
                    }
                  );
                  this.fetchData();
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <ObservationModal
          data={this.state.currentRow}
          isOpened={this.state.modalOpened}
          onClose={this.closeModal}
        />
      </div>
    );
  }

  render() {
    if (this.state.ready) {
      if (this.state.task) {
        return (
          <TaskContainer
            goBack={this.goBack}
            initTask={this.initTask}
            finishTask={this.finishTask}
            task={this.state.task}
            updateTask={this.updateTask}
            cancelTaskByIncidence={this.cancelTaskByIncidence}
          />
        );
      }

      return this.renderList();
    }

    return <LoaderData />;
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(UndressContainer);
