import React, { Component } from "react";
import Feathers from "../../redux/FeathersRedux";
import {
  Button,
  Form,
  Grid,
  Popup,
  Input,
  Search,
  Label,
} from "semantic-ui-react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { exportToXls } from "../../utils/Utils";
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";
import DayPickerInput from "react-day-picker/DayPickerInput";
import moment from "moment";

const renderClientSearchResults = ({ id, nombre }) => (
  <Label key={id} content={nombre} />
);
class DetailVulcanizationContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      total: 0,
      page: 0,
      loading: false,

      currentRow: {},
      isEditOpened: false,
      // results of the search querys
      results: null,
      // result objects

      shouldExport: false,

      client: "",
      client_id: null,

      producto_options: [],

      modalOpened: false,
      pageSize: 100,
      filterOptions: {
        id: null,
        ot: null,
        stageId: null,
        filterByRodillo: null,
        filterByPartida: null,
        filterByPedido: null,
        rodilloValue: null,
        producto_id: null,
        fecha_entrega: null,
        client_id: null,
      },
      $sortDirection: 1,
      // loading of the search bars
      loadingSearch: false,
      columna: "",
      sortDirection: false,
    };

    this.fetchData = this.fetchData.bind(this);
    this.fetchDropdownOptions = this.fetchDropdownOptions.bind(this);
    this.changeProduct = this.changeProduct.bind(this);
  }

  async fetchData(state = {}) {
    let pageSize = 100;

    let query = {
      $limit: pageSize,
      $skip: 100 * state.page || 0,
    };

    if (this.state.filterOptions.id) {
      query["partida_id"] = this.state.filterOptions.id;
    }

    if (this.state.filterOptions.ot) {
      query["pedido_id"] = this.state.filterOptions.ot;
    }

    if (this.state.filterOptions.rodilloValue) {
      query["rodillo_id"] = this.state.filterOptions.rodilloValue;
    }

    if (this.state.filterOptions.client_id) {
      query["client_id"] = this.state.filterOptions.client_id;
    }

    if (this.state.filterOptions.producto_id) {
      query["producto_id"] = this.state.filterOptions.producto_id;
    }

    if (this.state.filterOptions.fecha_entrega) {
      query["fecha_entrega"] = moment(
        this.state.filterOptions.fecha_entrega
      ).format("YYYY-MM-DD");
    }

    this.setState({ loading: true }, () => {
      Feathers.service("historial-vulcanizado")
        .find({
          query,
        })
        .then((res) => {
          console.log("res", res.data);

          if (!this.state.shouldExport) {
            this.setState({
              data: res.data,
              total: res.total,
              loading: false,
              page: state.page,
              pageSize: state.pageSize || 100,
            });
          }
          if (this.state.shouldExport) {
            exportToXls(res.data, "vulcanization_details");
            this.setState({
              shouldExport: false,
              loading: false,
              page: state.page,
              pageSize: state.pageSize || 100,
            });
          }
        });
    });
  }

  handleCleanButton = () => {
    this.setState(
      {
        client: "",
        client_id: null,
        filterOptions: {
          ...this.state.filterOptions,
          id: null,
          ot: null,
          stageId: null,
          filterByRodillo: null,
          filterByPartida: null,
          filterByPedido: null,
          rodilloValue: null,
          producto_id: null,
          fecha_entrega: "",
          client_id: null,
        },
      },
      () => {
        this.fetchData(this.state);
      }
    );
  };

  handleClienteResultSelect = async (e, { result }) => {
    this.setState({ client: result.nombre });

    await this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        client_id: result.id,
      },
    });

    this.fetchData(this.state);
  };

  componentDidMount() {
    this.fetchDropdownOptions();
  }

  resetClientComponent = () =>
    this.setState({ clientLoading: false, clientResults: [], client: "" });

  handleClienteSearchChange = (e, { value }) => {
    this.setState({ clientLoading: true, client: value });

    setTimeout(() => {
      if (this.state.client.length < 1) return this.resetClientComponent();

      Feathers.service("cliente")
        .find({
          query: {
            nombre: { $like: `%${value}%` },
            activo: 1,
            $limit: 3,
          },
        })
        .then((res) => {
          this.setState({
            clientLoading: false,
            clientResults: res.data,
          });
        });
    }, 500);
  };

  onRodilloChange = (rodillo) => {
    this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        filterByRodillo: rodillo,
      },
    });
  };

  fetchDropdownOptions = async (_) => {
    console.log("------");
    let producto_options = await Feathers.service("producto").find({
      query: {
        $limit: "-1",
      },
    });

    let formated_producto_options = producto_options.map((v) => {
      return {
        key: v.id,
        value: v.id,
        text: v.nombre,
      };
    });

    this.setState({
      producto_options: formated_producto_options,
    });
  };

  changeProduct = (d) => {
    this.setState(
      {
        filterOptions: {
          ...this.state.filterOptions,
          producto_id: d,
        },
      },
      () => {
        this.fetchData(this.state);
      }
    );
  };

  render() {
    return (
      <div>
        <Grid.Row>
          <Grid.Column>
            <Form>
              <Form.Group>
                <Form.Field>
                  <label>OT</label>
                  <Input
                    type="number"
                    value={this.state.filterOptions.ot || ""}
                    onChange={(event, data) => {
                      this.setState({
                        filterOptions: {
                          ...this.state.filterOptions,
                          ot: data.value,
                        },
                      });
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Rodillo</label>
                  <Form.Input
                    type="number"
                    value={this.state.filterOptions.rodilloValue || ""}
                    onChange={(event, data) => {
                      this.setState({
                        filterOptions: {
                          ...this.state.filterOptions,
                          rodilloValue: data.value,
                        },
                      });
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Partida</label>
                  <Input
                    type="number"
                    value={this.state.filterOptions.id || ""}
                    onChange={(event, data) => {
                      this.setState({
                        filterOptions: {
                          ...this.state.filterOptions,
                          id: data.value,
                        },
                      });
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Cliente</label>
                  <Search
                    icon=""
                    loading={this.state.clientLoading}
                    value={this.state.client}
                    results={this.state.clientResults}
                    onResultSelect={this.handleClienteResultSelect}
                    onSearchChange={this.handleClienteSearchChange}
                    resultRenderer={renderClientSearchResults}
                    placeholder="Cliente"
                    className="full-width"
                  />
                </Form.Field>
                {/* <Form.Field>
                  <label>Producto</label>
                  <Form.Dropdown
                    selection
                    options={this.state.producto_options}
                    onChange={(e, { value }) => {
                      this.changeProduct(value);
                    }}
                    value={this.state.filterOptions.productValue}
                  />
                </Form.Field> */}
                <Form.Field>
                  <label>Fecha Entrega</label>
                  <DayPickerInput
                    value={this.state.filterOptions.fecha_entrega}
                    format="YYYY-MM-DD"
                    formatDate={formatDate}
                    parseDate={parseDate}
                    onDayChange={(date) => {
                      this.setState(
                        {
                          filterOptions: {
                            ...this.state.filterOptions,
                            fecha_entrega: date,
                          },
                        },
                        (_) => this.fetchData(this.state)
                      );
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Filtrar</label>
                  <Button
                    basic
                    circular
                    icon="filter"
                    onClick={this.fetchData}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Limpiar</label>
                  <Button
                    basic
                    color="red"
                    circular
                    icon="x"
                    onClick={this.handleCleanButton}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Exportar</label>
                  <Button
                    basic
                    color="green"
                    circular
                    icon="file excel outline"
                    onClick={() => {
                      this.setState({ shouldExport: true }, () => {
                        this.fetchData(this.state);
                        console.log("exporting");
                      });
                    }}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
        <ReactTable
          columns={[
            { Header: "OT", accessor: "pedido_id", maxWidth: 100 },
            { Header: "SAE", accessor: "ref_sae", maxWidth: 100 },
            { Header: "Partida", accessor: "partida_id", maxWidth: 100 },
            { Header: "Rodillo", accessor: "rodillo_id", maxWidth: 100 },
            { Header: "Cliente", accessor: "cliente_nombre" },
            { Header: "Producto", accessor: "producto_nombre" },
            { Header: "Fecha Ent.", accessor: "fecha_entrega" },
            {
              Header: "Fecha Inicio Vulcanizado",
              accessor: "hora_inicio_vulcanizado",
            },
            { Header: "Fecha Fin Vulcanizado", accessor: "hora_fin" },
            {
              Header: "DN/Altura",
              Cell: (row) =>
                parseInt(row.original.dn)
                  ? row.original.dn
                  : row.original.altura_espec,
              maxWidth: 80,
            },
            {
              Header: "DE/Espesor",
              Cell: (row) =>
                parseInt(row.original.de)
                  ? row.original.de
                  : row.original.espesor_espec,
              maxWidth: 80,
            },
            {
              Header: "LC/Perímetro",
              Cell: (row) =>
                parseInt(row.original.lc)
                  ? row.original.lc
                  : row.original.perimetro_espec,
              maxWidth: 80,
            },
            { Header: "LT", accessor: "lt", maxWidth: 80 },
            { Header: "Cantidad", accessor: "cantidad", maxWidth: 80 },
            { Header: "Dureza", accessor: "dureza", maxWidth: 80 },
            { Header: "Kilos", accessor: "kilos", maxWidth: 100 },
            {
              Header: "Acciones",
              sortable: false,
              maxWidth: 80,
              Cell: (row) => {
                row = row.original;
                return (
                  <>
                    <Button
                      onClick={() =>
                      {
                        console.log("----", row)
                        this.props.dispatch(
                          push(`/tasks/vulcanizadoInfo/${row.vulcanizado_id}/${row.rodillo_id}`)
                        )
                      }
                     
                      }
                      basic
                      icon="play"
                      size="mini"
                      color="blue"
                      content="Ver"
                    />
                  </>
                );
              },
            },
          ]}
          manual
          data={this.state.data}
          loading={this.state.loading}
          pages={Math.ceil(this.state.total / this.state.pageSize)}
          onFetchData={this.fetchData}
          //page={this.state.page}
          defaultPageSize={100}
          style={{ textAlign: "center" }}
          onSortedChange={async (newSorted, column, shiftKey) => {
            let direction;
            if (column.Header == this.state.columna.Header) {
              direction = this.state.sortDirection ? false : true;
            } else {
              direction = false;
            }

            this.setState({
              columna: column,
              sortDirection: direction,
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(DetailVulcanizationContainer);
