import React, { Component } from "react";
import Feathers from "../../redux/FeathersRedux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import TaskStatus from "../../components/Tasks/TaskStatus";
import {
  Button,
  Grid,
  Header,
  Icon,
  Modal,
  Container,
  Label,
  Search,
  Form,
} from "semantic-ui-react";
import swal from "sweetalert2";
import moment from "moment";
import TaskContainer from "../../components/Tasks/QA/QATask";
import Config from "../../config/index";
import { connect } from "react-redux";
import Notifications, { notify } from "react-notify-toast";
import { exportToXls } from "../../utils/Utils";
import LoaderData from "../LoaderData";
import ObservationModal from "../../components/ObservationModal";
import Toggle from "react-toggle";
import TasksFilter from "../../components/FIlters/TasksFilter";

const renderClientSearchResults = ({ id, nombre }) => (
  <Label key={id} content={nombre} />
);

const renderMaterialSearchResults = ({ id, nombre }) => (
  <Label key={id} content={nombre} />
);

const SERVICE = "control_calidad";

class QAContainerOthers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tasks: [],
      total: 0,
      page: 0,
      loading: false,
      pageSize: 100,
      filterValues: {
        rodilloValue: null,
        partidaValue: null,
        pedidoValue: null,
        fechaEntrega: null,
      },
      ///
      ready: false,
      task: null,
      filters: [
        "pedido",
        "rodillo",
        "partida",
        "costumer",
        "material",
        "entrega",
      ],
      modalOpen: false,
      imageToOpen: "",
      filterOptions: {
        ref_sae: null,
        client_id: null,
      },

      // Search bar object

      sae: null,
      client: null,
      shouldExport: false,

      // Search bar result

      saeResults: null,
      clientResults: null,

      // Search bar loading

      saeLoading: null,
      clientLoading: null,
      modalOpened: false,
      currentRow: {},
      columna: "",
      sortDirection: false,
      showSae: false,
      tableColumns: [
        {
          Header: "OT",
          headerStyle: { fontSize: 20 },
          maxWidth: 100,
          Cell: (row) => {
            if (row.original.rodillo.partida.pedido.id)
              return <span>{row.original.rodillo.partida.pedido.id}</span>;
            return "---";
          },
        },
        {
          Header: "SAE",
          headerStyle: { fontSize: 20 },
          maxWidth: 100,
          Cell: (row) => {
            if (row.original.rodillo.partida.pedido.ref_sae)
              return <span>{row.original.rodillo.partida.pedido.ref_sae}</span>;
            return "---";
          },
        },
        {
          Header: "Rodillo",
          headerStyle: { fontSize: 20 },
          Cell: (row) => {
            if (row.original.rodillo.partida.dibujo) {
              return (
                <a
                  href="#"
                  onClick={() =>
                    this.handleOpen(row.original.rodillo.partida.dibujo.imagen)
                  }
                >
                  {" "}
                  {row.original.rodillo.id}
                </a>
              );
            } else {
              return <span>{row.original.rodillo.id}</span>;
            }
          },
        },
        {
          Header: "Partida",
          headerStyle: { fontSize: 20 },
          id: "partida_id",
          accessor: (d) => {
            if (d.rodillo.partida_id) return d.rodillo.partida_id;
            return "---";
          },
        },
        {
          Header: "Cliente",
          headerStyle: { fontSize: 20 },
          Cell: (row) => {
            return (
              <span>{row.original.rodillo.partida.pedido.cliente.nombre}</span>
            );
          },
        },
        {
          Header: "Tipo",
          headerStyle: { fontSize: 20 },
          Cell: (row) => {
            if (row.original.rodillo.partida.producto.nombre) {
              return row.original.rodillo.partida.producto.nombre;
            } else {
              return "---";
            }
          },
        },
        {
          Header: "Material",
          headerStyle: { fontSize: 20 },
          Cell: (row) => {
            return (
              <span>
                {row.original.rodillo.partida.material
                  ? row.original.rodillo.partida.material.nombre
                  : "---"}
              </span>
            );
          },
        },
        {
          Header: "Dureza",
          headerStyle: { fontSize: 20 },
          maxWidth: 100,
          Cell: (row) => {
            return (
              <span>
                {row.original.rodillo.partida.dureza
                  ? row.original.rodillo.partida.dureza.valor
                  : "---"}
              </span>
            );
          },
        },
        {
          Header: "Cantidad",
          headerStyle: { fontSize: 20 },
          maxWidth: 100,
          Cell: (row) => {
            return (
              <span>
                {row.original.rodillo.partida.cantidad
                  ? row.original.rodillo.partida.cantidad
                  : "---"}
              </span>
            );
          },
        },
        { Header: "", sortable: false, Cell: this.renderActions },
      ],
    };

    this.renderList = this.renderList.bind(this);
    this.fetchData = this.fetchData.bind(this);
    // this.renderActions = this.renderActions.bind(this);
    this.checkActiveTasks = this.checkActiveTasks.bind(this);
    this.finishTask = this.finishTask.bind(this);
    this.initTask = this.initTask.bind(this);
    this.updateTask = this.updateTask.bind(this);
    this.viewTask = this.viewTask.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.goBack = this.goBack.bind(this);
    this.cancelTaskByIncidence = this.cancelTaskByIncidence.bind(this);
    this.handleSaeToggle = this.handleSaeToggle.bind(this);
  }

  openModal = (row) => {
    this.setState({
      modalOpened: true,
      currentRow: row.rodillo.partida.observaciones,
    });
  };
  closeModal = () => {
    this.setState({
      modalOpened: false,
    });
  };

  handleResultSaeSelect = async (e, { result }) => {
    this.setState({ sae: result.ref_sae });

    await this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        ref_sae: result.ref_sae,
      },
    });

    this.fetchData(this.state);
  };

  handleSort = (array) => {
    let column = this.state.columna;
    switch (column.Header) {
      case "OT":
        array.data.sort((a, b) => {
          return (
            a.rodillo.partida.pedido.ref_sae - b.rodillo.partida.pedido.ref_sae
          );
        });
        break;
      case "Cliente":
        array.data.sort((a, b) => {
          const nameA = a.rodillo.partida.pedido.cliente.nombre.toUpperCase(); // ignore upper and lowercase
          const nameB = b.rodillo.partida.pedido.cliente.nombre.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Rodillo":
        array.data.sort((a, b) => {
          let A = a.rodillo.id;
          let B = b.rodillo.id;
          return A - B;
        });
        break;
      case "Tipo":
        array.data.sort((a, b) => {
          const nameA = a.rodillo.partida.producto.nombre.toUpperCase(); // ignore upper and lowercase
          const nameB = b.rodillo.partida.producto.nombre.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Material":
        console.log(array.data);
        array.data.sort((a, b) => {
          let nameA = a.rodillo.partida.material
            ? a.rodillo.partida.material.nombre.toUpperCase()
            : ""; // ignore upper and lowercase
          let nameB = b.rodillo.partida.material
            ? b.rodillo.partida.material.nombre.toUpperCase()
            : ""; // ignore upper and lowercase

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Dureza":
        array.data.sort((a, b) => {
          let A = a.rodillo.partida.dureza ? a.rodillo.partida.dureza.valor : 0;
          let B = b.rodillo.partida.dureza ? b.rodillo.partida.dureza.valor : 0;
          return A - B;
        });
        break;
      case "Cantidad":
        array.data.sort((a, b) => {
          let A = a.rodillo.partida.cantidad;
          let B = b.rodillo.partida.cantidad;
          return A - B;
        });
        break;
      default:
        break;
    }
    if (this.state.sortDirection) {
      array.data.reverse();
    }
    return array;
  };
  handleClienteResultSelect = async (e, { result }) => {
    this.setState({ client: result.nombre });

    await this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        client_id: result.id,
      },
    });

    this.fetchData(this.state);
  };

  handleSearchSaeChange = (e, { value }) => {
    this.setState({ saeLoading: true, sae: value });

    setTimeout(() => {
      if (this.state.sae.length < 1) return this.resetSaeComponent();

      Feathers.service("pedido")
        .find({
          query: {
            ref_sae: { $like: `%${value}%` },
            $limit: 5,
          },
        })
        .then((res) => {
          this.setState({
            saeLoading: false,
            saeResults: res.data,
          });
        });
    }, 500);
  };

  handleClienteSearchChange = (e, { value }) => {
    this.setState({ clientLoading: true, client: value });

    setTimeout(() => {
      if (this.state.client.length < 1) return this.resetClientComponent();

      Feathers.service("cliente")
        .find({
          query: {
            nombre: { $like: `%${value}%` },
            activo: 1,
            $limit: 3,
          },
        })
        .then((res) => {
          this.setState({
            clientLoading: false,
            clientResults: res.data,
          });
        });
    }, 500);
  };

  resetSaeComponent = () =>
    this.setState({ saeLoading: false, saeResults: [], sae: "" });
  resetClientComponent = () =>
    this.setState({ clientLoading: false, clientResults: [], client: "" });

  handleCleanButton = (_) => {
    this.resetClienteComponent();
    this.resetMaterialComponent();
    this.setState(
      {
        filterValues: {
          rodilloValue: null,
          partidaValue: null,
          pedidoValue: null,
          fechaEntrega: null,
        },
        filterOptions: {
          cliente_id: null,
          material_id: null,
          ref_sae: null,
        },
      },
      () => {
        this.fetchData(this.state);
      }
    );
  };

  cancelTaskByIncidence(incidencia_id) {
    Feathers.service("control_calidad")
      .patch(this.state.task.id, {
        status_tarea_id: 3,
        incidencia_id,
        hora_fin: moment().format("YYYY-MM-DD HH:mm:ss"),
      })
      .then((res) => {
        this.setState({ reloading: true, task: null });

        notify.show("Incidencia registrada.", "success");

        // Feathers.service('control_calidad').get(res.id)
        //     .then(task => this.setState({ task, reloading: false }))
      });
  }

  goBack() {
    this.setState({ task: null });
  }

  handleOpen = (logicImageUrl) => {
    var imageToOpen = Config.apiUrl + logicImageUrl;
    this.setState({ modalOpen: true, imageToOpen: imageToOpen });
  };

  handleClose = () => {
    this.setState({ modalOpen: false });
  };

  handleClienteResultSelect = async (e, { result }) => {
    this.setState({ cliente: result.nombre });

    this.setState(
      {
        filterOptions: {
          ...this.state.filterOptions,
          cliente_id: result.id,
        },
      },
      () => this.fetchData(this.state)
    );
  };

  handleMaterialResultSelect = async (e, { result }) => {
    this.setState({ material: result.nombre });

    this.setState(
      {
        filterOptions: {
          ...this.state.filterOptions,
          material_id: result.id,
        },
      },
      () => this.fetchData(this.state)
    );
  };

  handleClienteSearchChange = (e, { value }) => {
    this.setState({ clienteLoading: true, cliente: value });

    this.timeout = setTimeout(() => {
      if (this.state.cliente.length < 1) return this.resetClienteComponent();

      Feathers.service("cliente")
        .find({
          query: {
            nombre: { $like: `%${value}%` },
            activo: 1,
            $limit: 3,
          },
        })
        .then((res) => {
          this.setState({
            clienteLoading: false,
            clienteResults: res.data,
          });
        });
    }, 500);
  };

  handleMaterialSearchChange = (e, { value }) => {
    this.setState({ materialLoading: true, material: value });

    setTimeout(() => {
      if (this.state.material.length < 1) return this.resetMaterialComponent();

      Feathers.service("material")
        .find({
          query: {
            nombre: { $like: `%${value}%` },
            activo: 1,
            $limit: 3,
          },
        })
        .then((res) => {
          this.setState({
            materialLoading: false,
            materialResults: res.data,
          });
        });
    }, 500);
  };

  onPartidaChange = (partida) => {
    this.setState(
      {
        filterValues: {
          ...this.state.filterValues,
          partidaValue: partida,
        },
      },
      () => this.fetchData(this.state)
    );
  };

  onChangeRodillo = (rodillo) => {
    this.setState(
      {
        filterValues: {
          ...this.state.filterValues,
          rodilloValue: rodillo,
        },
      },
      () => this.fetchData(this.state)
    );
  };

  onChangePedido = (pedido) => {
    this.setState(
      {
        filterValues: {
          ...this.state.filterValues,
          pedidoValue: pedido,
        },
      },
      () => this.fetchData(this.state)
    );
  };

  onDateChange = (date) => {
    const dateFormat = moment(date).format("YYYY-MM-DD");
    this.setState(
      {
        filterValues: {
          ...this.state.filterValues,
          fechaEntrega: dateFormat,
        },
      },
      () => this.fetchData(this.state)
    );
  };

  resetClienteComponent = () => {
    this.setState({ clienteLoading: false, clienteResults: [], cliente: "" });
  };

  resetMaterialComponent = () => {
    this.setState({
      materialLoading: false,
      materialResults: [],
      material: "",
    });
  };

  componentDidMount() {
    this.checkActiveTasks();
  }

  checkActiveTasks() {
    Feathers.service(SERVICE)
      .find({
        query: {
          usuario_id: this.props.user.id,
          status_tarea_id: 1,
          $table: true,
        },
      })
      .then((res) => {
        if (res.total > 0) {
          this.setState({
            task: res.data[0],
            ready: true,
          });
        } else {
          this.setState({
            ready: true,
          });
        }
      });
  }

  fetchData = async (state = {}) => {
    await this.setState({
      loading: true,
    });

    let pageSize = state.pageSize || 10;

    let partidas = await Feathers.service("partida").find({
      query: {
        $limit: "-1",
        producto_id: {
          $nin: [1, 5, 2, 4], // para no traer rodillos, no manchetas y ahora tampoco rectificados
        },
      },
    });

    let partidasIdes = partidas.map((v) => v.id);

    let rodillosPermitidos = await Feathers.service("rodillo").find({
      query: {
        $limit: -1,
        partida_id: {
          $in: partidasIdes,
        },
      },
    });

    let rodillosIdes = rodillosPermitidos.map((v) => v.id);

    let query = {
      $limit: this.state.shouldExport ? "-1" : pageSize,
      $skip: pageSize * state.page,
      $table: true,
      $or: [{ status_tarea_id: null }, { status_tarea_id: 1 }],
      rodillo_id: {
        $in: rodillosIdes,
      },
    };

    let filteredRodillos = [];

    let isFiltered = false;

    if (this.state.filterValues.pedidoValue) {
      let pedidos_id = await Feathers.service("pedido").find({
        query: {
          $limit: "-1",
          id: this.state.filterValues.pedidoValue,
        },
      });

      let pedidos_ids = pedidos_id.map((v) => v.id);

      let partidas_de_los_pedidos = await Feathers.service("partida").find({
        query: {
          $limit: "-1",
          producto_id: {
            $nin: [1, 5, 2, 4], // para no traer rodillos, no manchetas y ahora tampoco rectificados
          },
          pedido_id: {
            $in: pedidos_ids,
          },
        },
      });

      let partidas_ids = partidas_de_los_pedidos.map((v) => v.id);

      let rodillos_de_las_partidas = await Feathers.service("rodillo").find({
        query: {
          $limit: "-1",
          partida_id: {
            $in: partidas_ids,
          },
        },
      });

      let rodillos_ids = rodillos_de_las_partidas.map((v) => v.id);

      filteredRodillos = [...filteredRodillos, ...rodillos_ids];

      isFiltered = true;
    }

    if (this.state.filterValues.partidaValue) {
      let rodillos_de_las_partidas = await Feathers.service("rodillo").find({
        query: {
          $limit: "-1",
          partida_id: this.state.filterValues.partidaValue,
        },
      });

      let rodillos_ids = rodillos_de_las_partidas.map((v) => v.id);

      filteredRodillos = [...filteredRodillos, ...rodillos_ids];

      isFiltered = true;
    }

    if (this.state.filterValues.fechaEntrega) {
      let pedidos_id = await Feathers.service("pedido").find({
        query: {
          $limit: "-1",
          fecha_entrega: this.state.filterValues.fechaEntrega,
        },
      });

      let pedidos_ids = pedidos_id.map((v) => v.id);

      let partidas_de_los_pedidos = await Feathers.service("partida").find({
        query: {
          $limit: "-1",
          pedido_id: {
            $in: pedidos_ids,
          },
        },
      });

      let partidas_ids = partidas_de_los_pedidos.map((v) => v.id);

      let rodillos_de_las_partidas = await Feathers.service("rodillo").find({
        query: {
          $limit: "-1",
          partida_id: {
            $in: partidas_ids,
          },
        },
      });

      let rodillos_ids = rodillos_de_las_partidas.map((v) => v.id);

      filteredRodillos = [...filteredRodillos, ...rodillos_ids];

      isFiltered = true;
    }

    if (this.state.filterOptions.material_id) {
      let partidas_con_material = await Feathers.service("partida").find({
        query: {
          $limit: "-1",
          producto_id: {
            $nin: [1, 5, 2, 4], // para no traer rodillos, no manchetas y ahora tampoco rectificados
          },
          material_id: this.state.filterOptions.material_id,
        },
      });

      let partidas_ids = partidas_con_material.map((v) => v.id);

      let rodillos_de_las_partidas = await Feathers.service("rodillo").find({
        query: {
          $limit: "-1",
          partida_id: {
            $in: partidas_ids,
          },
        },
      });

      let rodillos_ids = rodillos_de_las_partidas.map((v) => v.id);

      filteredRodillos = [...filteredRodillos, ...rodillos_ids];

      isFiltered = true;
    }

    // if any other rodillo has not been filtered by other non related filters then just filter one rodillo
    if (this.state.filterValues.rodilloValue && !filteredRodillos.length) {
      query["rodillo_id"] = this.state.filterValues.rodilloValue;
    }
    if (this.state.filterValues.rodilloValue && filteredRodillos.length) {
      filteredRodillos = [
        ...filteredRodillos,
        this.state.filterValues.rodilloValue,
      ];

      isFiltered = true;
    }

    if (this.state.filterOptions.cliente_id) {
      console.log(this.state.filterOptions.cliente_id);
      let pedidos_del_cliente = await Feathers.service("pedido").find({
        query: {
          $limit: "-1",
          cliente_id: this.state.filterOptions.cliente_id,
        },
      });

      let pedidos_ids = pedidos_del_cliente.map((v) => v.id);

      let partidas_de_los_pedidos = await Feathers.service("partida").find({
        query: {
          $limit: "-1",
          producto_id: {
            $nin: [1, 5, 2, 4],
          },
          pedido_id: {
            $in: pedidos_ids,
          },
        },
      });

      let partidas_ids = partidas_de_los_pedidos.map((v) => v.id);

      let rodillos_de_las_partidas = await Feathers.service("rodillo").find({
        query: {
          $limit: "-1",
          partida_id: {
            $in: partidas_ids,
          },
        },
      });

      let rodillos_ids = rodillos_de_las_partidas.map((v) => v.id);

      filteredRodillos = [...filteredRodillos, ...rodillos_ids];

      isFiltered = true;
    }

    if (filteredRodillos.length || isFiltered) {
      query["rodillo_id"] = {
        $in: filteredRodillos,
      };
    }

    if (this.state.shouldExport) {
      delete query.$table;
    }

    this.setState({ loading: true }, () => {
      Feathers.service(SERVICE)
        .find({
          query,
        })
        .then((res) => {
          res = this.handleSort(res);

          if (res.data) {
            if (this.state.columna === "") {
              res.data.sort((a, b) => {
                const nameA = a.rodillo.partida.pedido.fecha_entrega.toUpperCase();
                const nameB = b.rodillo.partida.pedido.fecha_entrega.toUpperCase();

                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }
                return 0;
              });
            }
          } else {
            if (this.state.columna === "") {
              res.sort((a, b) => {
                const nameA = a.rodillo.partida.pedido.fecha_entrega.toUpperCase();
                const nameB = b.rodillo.partida.pedido.fecha_entrega.toUpperCase();

                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }
                return 0;
              });
            }
          }

          if (!this.state.shouldExport) {
            this.setState({
              tasks: res.data,
              total: res.total,
              loading: false,
              page: state.page,
              pageSize: state.pageSize || 100,
            });
          }

          if (this.state.shouldExport) {
            exportToXls(res, "task_qa_otros");
            this.setState({
              shouldExport: false,
              loading: false,
              page: state.page,
              pageSize: state.pageSize || 100,
            });
          }
        });
    });
  };

  viewTask(task) {
    Feathers.service("control_calidad")
      .get(task.id)
      .then((task) => this.setState({ task }));
  }

  async initTask(task) {
    return await swal({
      title: "¿Iniciar tarea de control de calidad?",
      type: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((res) => {
      if (res.value) {
        Feathers.service(SERVICE)
          .patch(task.id, {
            hora_inicio: moment().format("YYYY-MM-DD HH:mm:ss"),
            status_tarea_id: 1,
            usuario_id: this.props.user.id,
          })
          .then((res) => {
            Feathers.service(SERVICE)
              .get(res.id)
              .then((task) => this.setState({ task }));
          });
      }
    });
  }

  finishTask() {
    swal({
      title: "¿Finalizar tarea de control de calidad?",
      text: "Observaciones adicionales",
      type: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
      reverseButtons: true,
      input: "text",
      inputLabel: "Comentarios",
      inputValidator: async (value) => {
        if (value.length > 255) {
          return "Solo se permiten comentarios de 255 carácteres de longitud.";
        }
      },
    }).then((res) => {
      if (res.value) {
        //Este modal se usará por mientras, en lo que se encuentra el error en el tiempo de carga al finalizar una tarea de control de calidad
        /* let timerInterval
                  swal.fire({
                  title: 'Finalizando Control de Calidad',
                  html: 'Cargando...',
                  timer: 6000,
                  timerProgressBar: true,
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  onBeforeOpen: () => {
                      swal.showLoading()
                      timerInterval = setInterval(() => {
                      const content = swal.getContent()
                      if (content) {
                          const b = content.querySelector('b')
                          if (b) {
                          b.textContent = swal.getTimerLeft()
                          }
                      }
                      }, 100)
                  },
                  onClose: () => {
                      clearInterval(timerInterval)
                  }
                  }).then((result) => {
                  if (result.dismiss === swal.DismissReason.timer) {
                      console.log('')
                  }
              }) */

        Feathers.service("control_calidad")
          .patch(this.state.task.id, {
            hora_fin: moment().format("YYYY-MM-DD HH:mm:ss"),
            status_tarea_id: 2,
            comentarios: res.value,
          })

          .then(async (res) => {
            const checkNotification = await Feathers.service(
              "notification_tasks"
            ).get(12);
            if (checkNotification.activo) {
              Feathers.service("notificacion").create({
                mensaje: `Se ha finalizado una tarea de control calidad otros #${this.state.task.id}`,
                perfil_id: "1",
                fecha_hora: moment().format("YYYY-MM-DD HH:mm:ss"),
                tipo: 4,
              });
            }
            this.setState({
              task: null,
            });
          });
      }
    });
  }

  closeTask = () => {
    this.setState({
      task: null,
    });
  };

  updateTask() {
    Feathers.service("control_calidad")
      .get(this.state.task.id)
      .then((task) => this.setState({ task }));
  }

  renderActions = (row) => {
    row = row.original;
    return (
      <>
        <Button
          onClick={() => this.viewTask(row)}
          icon="play"
          size="mini"
          basic
          color="blue"
          content="Ver"
        />
        <Button
          onClick={() => this.openModal(row)}
          icon="eye"
          size="mini"
          basic
          color="blue"
          content="Obser."
          style={{ backgroundColor: "#0098f0" }}
        />
      </>
    );
  };

  handleSaeToggle = () => {
    this.setState(
      {
        showSae: !this.state.showSae,
      },
      () => {
        console.log(this.state);
      }
    );
    console.log(this.state.showSae);
  };

  renderList() {
    return (
      <div>
        <Modal
          open={this.state.modalOpen}
          onClose={this.handleClose}
          size="small"
        >
          <Header icon="browser" content="Rodillo" />
          <Modal.Content>
            <Container fluid textAlign="center">
              <img src={this.state.imageToOpen} />
            </Container>
          </Modal.Content>
          <Modal.Actions>
            <Button color="green" onClick={this.handleClose} inverted>
              <Icon name="checkmark" /> Ok
            </Button>
          </Modal.Actions>
        </Modal>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Header
                as="h2"
                icon="building"
                content="Control de Calidad - Otros"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Form>
                <Form.Group>
                  <TasksFilter
                    loading={this.state.loading}
                    clienteLoading={this.state.clienteLoading}
                    filters={this.state.filters}
                    costumer={this.state.cliente || ""}
                    costumers={this.state.clienteResults}
                    onSelectCostumer={this.handleClienteResultSelect}
                    onSearchCostumerChange={this.handleClienteSearchChange}
                    costumerResultRenderer={renderClientSearchResults}
                    partidaValue={this.state.filterValues.partidaValue || ""}
                    onChangePartida={this.onPartidaChange}
                    rodilloValue={this.state.filterValues.rodilloValue || ""}
                    onChangeRodillo={this.onChangeRodillo}
                    fechaEntrega={this.state.filterValues.fechaEntrega}
                    onDateChange={this.onDateChange}
                    materialLoading={this.state.materialLoading}
                    materials={this.state.materialResults}
                    material={this.state.material ? this.state.material : ""}
                    handleMaterialResultSelect={this.handleMaterialResultSelect}
                    handleMaterialSearchChange={this.handleMaterialSearchChange}
                    renderMaterialSearchResults={renderMaterialSearchResults}
                    pedidoValue={this.state.filterValues.pedidoValue || ""}
                    onPedidoChange={this.onChangePedido}
                  />
                  <Form.Field>
                    <label>Limpiar</label>
                    <Button
                      basic
                      color="red"
                      circular
                      icon="x"
                      onClick={this.handleCleanButton}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Exportar</label>
                    <Button
                      basic
                      color="green"
                      circular
                      icon="file excel outline"
                      onClick={() => {
                        this.setState({ shouldExport: true }, () => {
                          this.fetchData(this.state);
                        });
                      }}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <ReactTable
                columns={this.state.tableColumns}
                manual
                data={this.state.tasks}
                loading={this.state.loading}
                pages={Math.ceil(this.state.total / this.state.pageSize)}
                onFetchData={this.fetchData}
                defaultPageSize={100}
                style={{ textAlign: "center" }}
                onSortedChange={async (newSorted, column, shiftKey) => {
                  let direction;
                  if (column.Header == this.state.columna.Header) {
                    direction = this.state.sortDirection ? false : true;
                  } else {
                    direction = false;
                  }

                  this.setState(
                    {
                      columna: column,
                      sortDirection: direction,
                    },
                    () => {
                      console.log(this.state);
                    }
                  );
                  this.fetchData();
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <ObservationModal
          data={this.state.currentRow}
          isOpened={this.state.modalOpened}
          onClose={this.closeModal}
        />
      </div>
    );
  }

  render() {
    if (this.state.ready) {
      if (this.state.task) {
        return (
          <TaskContainer
            goBack={this.goBack}
            initTask={this.initTask}
            finishTask={this.finishTask}
            task={this.state.task}
            updateTask={this.updateTask}
            cancelTaskByIncidence={this.cancelTaskByIncidence}
            closeTask={this.closeTask}
          />
        );
      }

      return this.renderList();
    }

    return <LoaderData />;
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(QAContainerOthers);
