import React, { Component } from "react";
import Feathers from "../../redux/FeathersRedux";
import TaskStatus from "../../components/Tasks/TaskStatus";
import {
  Button,
  Grid,
  Header,
  Icon,
  Modal,
  Container,
  Form,
  Search,
  Label,
} from "semantic-ui-react";
import swal from "sweetalert2";
import moment from "moment";
import TaskContainer from "../../components/Tasks/Construction/ConstructionTask";
import { notify } from "react-notify-toast";
import Config from "../../config/index";
import { connect } from "react-redux";
import { exportToXls } from "../../utils/Utils";
import ReactTable from "react-table";
import "react-table/react-table.css";
import LoaderData from "../LoaderData";
import Toggle from "react-toggle";

const SERVICE = "construccion";

const renderSearchSaeResults = ({ id, ref_sae }) => (
  <Label key={id} content={ref_sae} />
);
const renderSearchParteResults = ({ id, parte }) => (
  <Label key={id} content={parte} />
);

class ConstruccionManchetasContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tasks: [],
      total: 0,
      page: 0,
      loading: false,
      pageSize: 100,
      shouldExport: false,
      ///
      ready: false,
      task: null,
      modalOpen: false,
      imageToOpen: "",
      colecciones: [],
      sae: "",
      parte: "",
      resultsSae: null,
      resultsParte: null,
      filterOptions: {
        ref_sae: null,
        parte: null,
      },
      loadingSAESearch: false,
      loadingParteSearch: false,
      columna: "",
      sortDirection: false,
      showSae: false,
      tableColumns: [
        {
          Header: "OT",
          Cell: (row) => {
            if (row.original.tareas[0].rodillo.partida.pedido)
              return (
                <span>{row.original.tareas[0].rodillo.partida.pedido.id}</span>
              );
            return "---";
          },
        },
        {
          Header: "SAE",
          Cell: (row) => {
            if (row.original.tareas[0].rodillo.partida.pedido)
              return (
                <span>
                  {row.original.tareas[0].rodillo.partida.pedido.ref_sae}
                </span>
              );
            return "---";
          },
        },
        {
          Header: "Parte",
          Cell: (row) => {
            return <span>{row.original ? row.original.parte : "---"}</span>;
          },
        },
        {
          Header: "Forma",
          Cell: (row) => {
            return <span>{row.original.forma}</span>;
          },
        },
        {
          Header: "Pendientes/totales",
          Cell: (row) => {
            return (
              <span>
                {row.original.totales}/{row.original.pendientes}
              </span>
            );
          },
        },
        {
          Header: "Altura",
          Cell: (row) => {
            if (row.original.tareas[0]) {
              if (row.original.tareas[0].rodillo.partida.mancheta)
                return (
                  <span>
                    {
                      row.original.tareas[0].rodillo.partida.mancheta
                        .altura_espec
                    }
                  </span>
                );
            }
            return "---";
          },
        },
        {
          Header: "Espesor",
          Cell: (row) => {
            if (row.original.tareas[0]) {
              if (row.original.tareas[0].rodillo.partida.mancheta)
                return (
                  <span>
                    {
                      row.original.tareas[0].rodillo.partida.mancheta
                        .espesor_espec
                    }
                  </span>
                );
            }
            return "---";
          },
        },
        {
          Header: "Perímetro",
          Cell: (row) => {
            if (row.original.tareas[0]) {
              if (row.original.tareas[0].rodillo.partida.mancheta)
                return (
                  <span>
                    {
                      row.original.tareas[0].rodillo.partida.mancheta
                        .perimetro_espec
                    }
                  </span>
                );
            }
            return "---";
          },
        },
        {
          Header: "Kilos",
          Cell: (row) => {
            if (row.original.tareas[0]) {
              if (row.original.tareas[0].rodillo.partida.mancheta)
                return (
                  <span>
                    {row.original.tareas[0].rodillo.partida.mancheta.kilos}
                  </span>
                );
            }
            return "---";
          },
        },
        {
          Header: "Dureza",
          Cell: (row) => {
            if (row.original.tareas[0]) {
              if (row.original.tareas[0].rodillo.partida.dureza)
                return (
                  <span>
                    {row.original.tareas[0].rodillo.partida.dureza.valor}
                  </span>
                );
            }
            return "---";
          },
        },
        {
          Header: "Espesor",
          Cell: (row) => {
            if (row.original.tareas[0]) {
              if (row.original.tareas[0].rodillo.partida.mancheta)
                return (
                  <span>
                    {
                      row.original.tareas[0].rodillo.partida.mancheta
                        .espesor_espec
                    }
                  </span>
                );
            }
            return "---";
          },
        },
        {
          Header: "Perimetro",
          Cell: (row) => {
            if (row.original.tareas[0]) {
              if (row.original.tareas[0].rodillo.partida.mancheta)
                return (
                  <span>
                    {
                      row.original.tareas[0].rodillo.partida.mancheta
                        .perimetro_espec
                    }
                  </span>
                );
            }
            return "---";
          },
        },
        {
          Header: "Acciones",
          sortable: false,
          Cell: (row) => {
            return (
              <Button
                basic
                onClick={() => this.viewTask(row.original.tareas[0])}
                icon="play"
                size="mini"
                color="blue"
                content="Ver"
              />
            );
          },
        },
      ],
    };
  }

  goBack = () => {
    this.setState({ task: null });
    this.fetchData(this.state);
    this.handleSaeToggle = this.handleSaeToggle.bind(this);
  };

  handleOpen = (logicImageUrl) => {
    var imageToOpen = Config.apiUrl + logicImageUrl;
    this.setState({ modalOpen: true, imageToOpen: imageToOpen });
  };

  handleClose = () => {
    this.setState({ modalOpen: false });
  };

  checkActiveTasks = () => {
    Feathers.service(SERVICE)
      .find({
        query: {
          usuario_id: this.props.user.id,
          status_tarea_id: 1,
          $table: true,
        },
      })
      .then((res) => {
        if (res.total > 0) {
          if (res.data[0].rodillo.esMancheta === 1) {
            this.setState({
              task: res.data[0],
              ready: true,
            });
          } else {
            this.setState({
              ready: true,
            });
          }
        } else {
          this.setState({
            ready: true,
          });
        }
      });
  };
  handleSort = (array) => {
    let column = this.state.columna;
    switch (column.Header) {
      case "OT":
        array.data.sort((a, b) => {
          return (
            a.rodillo.partida.pedido.ref_sae - b.rodillo.partida.pedido.ref_sae
          );
        });
        break;
      case "Parte":
        array.data.sort((a, b) => {
          const nameA = a.original.parte.toUpperCase(); // ignore upper and lowercase
          const nameB = b.original.parte.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Forma":
        array.data.sort((a, b) => {
          const nameA = a.original.forma.toUpperCase(); // ignore upper and lowercase
          const nameB = b.original.forma.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Pendientes/totales":
        array.data.sort((a, b) => {
          let nameA = a.original.pendientes
            ? a.original.pendientes.toUpperCase()
            : ""; // ignore upper and lowercase
          let nameB = b.original.pendientes
            ? b.original.pendientes.toUpperCase()
            : ""; // ignore upper and lowercase

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Altura":
        array.data.sort((a, b) => {
          let A = a.rodillo.partida.mancheta.altura_espec;
          let B = b.rodillo.partida.mancheta.altura_espec;
          return A - B;
        });
        break;
      case "Dureza":
        array.data.sort((a, b) => {
          let A = a.rodillo.partida.dureza.valor;
          let B = b.rodillo.partida.dureza.valor;
          return A - B;
        });
        break;
      case "Espesor":
        array.data.sort((a, b) => {
          let A = a.rodillo.partida.mancheta.espesor_espec;
          let B = b.rodillo.partida.mancheta.espesor_espec;
          return A - B;
        });
        break;
      case "Perimetro":
        array.data.sort((a, b) => {
          let A = a.rodillo.partida.mancheta.perimetro_espec;
          let B = b.rodillo.partida.mancheta.perimetro_espec;
          return A - B;
        });
        break;
      default:
        break;
    }
    if (this.state.sortDirection) {
      array.data.reverse();
    }
    return array;
  };
  fetchData = async (state = {}) => {
    /*
      VAMOS A TENER QUE AGRUPAR LA INFORMACIÓN POR PARTE. ES DECIR, SÓLO MOSTRAREMOS LA PARTE Y LA INFORMACIÓN DE LA
      MANCHETA A CONSTRUIR A CONTINUACIÓN. SÓLO SE MOSTRARÁ UNA MANCHETA, QUE SERÁ LA SIGUIENTE EN CONSTRUIR.

      LA INFORMACIÓN A MOSTRAR YA NO SERÁ LA DE TASK, SINO OTRA, THIS.STATE.GROUPED_TASK:

      {
        parte: "", // información del grupo
        pentiente_totales: "",
        sae: "", // a partir de aquí es la información de la mancheta  a cargar
        altura: "",
        .....
        "estatus": "",
      }

      La mancheta a cargar será seleccionada de la siguiente manera en orden de priodidad.

      siempre siendo una mancheta perteneciente a la parte.
      1.- la mancheta tiene la tarea de construcción ya iniciada.
      2.- otra mancheta aleatoria.


      fetchData será llamado  cada vez que se entre a la lista, ya sea directo o porque se finalizó una tarea.
       */

    await this.setState({
      loading: true,
    });

    let manchetas = await Feathers.service("rodillo").find({
      query: {
        $limit: "-1",
        esMancheta: 1,
        $select: ["id"],
      },
    });
    let manchetasIds = manchetas.map((v) => v.id);

    let tareas_contruccion_sin_filtrar = await Feathers.service(SERVICE).find({
      query: {
        rodillo_id: {
          $in: manchetasIds,
        },
        $or: [{ status_tarea_id: null }, { status_tarea_id: 1 }],
        $limit: "-1",
      },
    });
    /*
     * Aqui vamos a handlear los filtros
     */

    if (this.state.filterOptions.ref_sae !== null) {
      tareas_contruccion_sin_filtrar = tareas_contruccion_sin_filtrar.filter(
        (v) => {
          if (
            v.rodillo.partida.pedido.ref_sae ===
            this.state.filterOptions.ref_sae
          )
            return v;
        }
      );
    }

    if (this.state.filterOptions.parte !== null) {
      tareas_contruccion_sin_filtrar = tareas_contruccion_sin_filtrar.filter(
        (v) => {
          if (
            v.rodillo.partida.mancheta.parte === this.state.filterOptions.parte
          )
            return v;
        }
      );
    }

    let tareas_contruccion = tareas_contruccion_sin_filtrar.filter((v) => {
      if (v.status_tarea_id === 1 || v.status_tarea_id === null) return v;
    });

    let partesSinFiltrar = tareas_contruccion.map((v) =>
      v.rodillo.partida.mancheta !== null ? v.rodillo.partida.mancheta.parte : 0
    );
    //let formasSinFiltrar = tareas_contruccion.map(v => v.rodillo.partida.mancheta.formamancheta.nombre);

    let partes = Array.from(new Set(partesSinFiltrar));
    //let formas = Array.from(new Set(formasSinFiltrar));

    let colecciones = [];

    colecciones = partes.map((v, i) => {
      return {
        parte: v,
        forma: "",
        pendientes: 0,
        totales: 0,
        tareas: [],
      };
    });

    for (let coleccion of colecciones) {
      for (let tarea of tareas_contruccion) {
        if (coleccion.parte) {
          if (coleccion.parte === tarea.rodillo.partida.mancheta.parte) {
            coleccion.tareas.push(tarea);
            coleccion.forma =
              tarea.rodillo.partida.mancheta.formamancheta.nombre;
          }
        }
      }
    }

    for (let coleccion of colecciones) {
      for (let sinfiltro of partesSinFiltrar) {
        if (sinfiltro === coleccion.parte) {
          coleccion.pendientes += 1;
        }
      }
    }

    /* Vamos a treaer todos las tareas de construccion que sean manchetas*/

    // para los totales
    let tareas_de_construccion_que_son_manchetas = tareas_contruccion_sin_filtrar.filter(
      (v) => {
        if (
          [1, 2].indexOf(v.rodillo.partida.pedido.status_id) !== -1 &&
          v.incidencia_id === null
        )
          return v;
      }
    );

    let partes_de_tareas_de_contruccion_que_son_manchetas = tareas_de_construccion_que_son_manchetas.map(
      (v) => v.rodillo.partida.mancheta.parte
    );

    for (let coleccion of colecciones) {
      for (let parte of partes_de_tareas_de_contruccion_que_son_manchetas) {
        if (coleccion.parte === parte) {
          coleccion.totales += 1;
        }
      }
    }
    console.log(colecciones, "manchetas");
    if (!this.state.shouldExport) {
      if (colecciones.length !== 0) {
        console.log(colecciones, "_--r");
        // this.handleSort(colecciones);
        // if (this.state.columna === "rodillo") {
        //   colecciones.sort((a, b) => {
        //     const nameA = a.rodillo.partida.pedido.fecha_entrega.toUpperCase();
        //     const nameB = b.rodillo.partida.pedido.fecha_entrega.toUpperCase();

        //     if (nameA < nameB) {
        //       return -1;
        //     }
        //     if (nameA > nameB) {
        //       return 1;
        //     }
        //     return 0;
        //   });
        // }
      }
      this.setState({
        colecciones,
        total: colecciones.length,
        loading: false,
      });
    }

    if (this.state.shouldExport) {
      exportToXls(colecciones, "task_construction_manchetas");
      this.setState({
        shouldExport: false,
        loading: false,
      });
    }
  };

  componentDidMount = () => {
    this.checkActiveTasks();
    this.fetchData();
  };

  viewTask = (task) => {
    Feathers.service("construccion")
      .get(task.id)
      .then((task) => this.setState({ task }));
  };

  initTask = (task) => {
    swal({
      title: "¿Iniciar tarea?",
      type: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((res) => {
      if (res.value) {
        Feathers.service(SERVICE)
          .patch(task.id, {
            hora_inicio: moment().format("YYYY-MM-DD HH:mm:ss"),
            status_tarea_id: 1,
            usuario_id: this.props.user.id,
          })
          .then((res) => {
            Feathers.service(SERVICE)
              .get(res.id)
              .then((task) => this.setState({ task }));
          });
      }
    });

    // swal("ver")
  };

  finishTask = () => {
    swal({
      title: "¿Finalizar tarea de construcción?",
      type: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((res) => {
      if (res.value) {
        Feathers.service(SERVICE)
          .patch(this.state.task.id, {
            hora_fin: moment().format("YYYY-MM-DD HH:mm:ss"),
            status_tarea_id: 2,
          })
          .then(async (res) => {
            const checkNotification = await Feathers.service(
              "notification_tasks"
            ).get(5);
            if (checkNotification.activo) {
              Feathers.service("notificacion").create({
                mensaje: `Se ha finalizado una tarea de constucción manchetas #${this.state.task.id}`,
                perfil_id: "1",
                fecha_hora: moment().format("YYYY-MM-DD HH:mm:ss"),
                tipo: 4,
              });
            }

            this.setState(
              {
                task: null,
              },
              () => this.fetchData(this.state)
            );
          });
      }
    });
  };

  cancelTaskByIncidence = (incidencia_id) => {
    Feathers.service("construccion")
      .patch(this.state.task.id, {
        incidencia_id,
        status_tarea_id: 3,
        hora_fin: moment().format("YYYY-MM-DD HH:mm:ss"),
      })
      .then((res) => {
        this.setState({ task: null });
        notify.show("Incidencia registrada", "success");
        this.fetchData(this.state);
      });
  };

  updateTask = () => {
    Feathers.service(SERVICE)
      .get(this.state.task.id)
      .then((task) => this.setState({ task }));
  };

  renderActions = (row) => {
    row = row.original;
    return (
      <Button
        onClick={() => this.viewTask(row)}
        icon="play"
        size="mini"
        color="green"
        content="Ver"
      />
    );
  };

  handleResultSaeSelect = async (e, { result }) => {
    this.setState({ sae: result.ref_sae });

    this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        ref_sae: result.ref_sae,
      },
    });

    this.fetchData(this.state);
  };

  handleResultParteSelect = async (e, { result }) => {
    this.setState({ parte: result.parte });

    await this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        parte: result.parte,
      },
    });
    this.fetchData(this.state);
  };

  handleSearchSaeChange = (e, { value }) => {
    this.setState({ loadingSAESearch: true, sae: value });

    setTimeout(() => {
      if (this.state.sae.length < 1) return this.resetComponent();

      Feathers.service("pedido")
        .find({
          query: {
            ref_sae: { $like: `%${value}%` },
          },
        })
        .then((res) => {
          this.setState({
            loadingSAESearch: false,
            resultsSae: res.data,
          });
        });
    }, 500);
  };

  handleSearchParteChange = (e, { value }) => {
    this.setState({ loadingParteSearch: true, parte: value });

    setTimeout(() => {
      if (this.state.parte.length < 1) return this.resetComponent();

      Feathers.service("manchetas")
        .find({
          query: {
            parte: { $like: `%${value}%` },
          },
        })
        .then((res) => {
          this.setState({
            loadingParteSearch: false,
            resultsParte: res.data,
          });
        });
    }, 500);
  };

  handleCleanButton = async () => {
    await this.setState({
      sae: "",
      parte: "",
      resultsSae: null,
      resultsParte: null,
      filterOptions: {
        ...this.state.filterOptions,
        ref_sae: null,
        parte: "",
      },
    });

    this.fetchData(this.state);
  };

  resetComponent = (_) => {
    this.setState({
      loadingSAESearch: false,
      loadingParteSearch: false,
      resultsParte: [],
      resultsSae: [],
      sae: "",
      parte: "",
    });
  };

  handleSaeToggle = () => {
    this.setState(
      {
        showSae: !this.state.showSae,
      },
      () => {
        console.log(this.state);
      }
    );
    console.log(this.state.showSae);
  };

  renderList() {
    return (
      <div>
        <Modal
          open={this.state.modalOpen}
          onClose={this.handleClose}
          size="small"
        >
          <Header icon="browser" content="Rodillo" />
          <Modal.Content>
            <Container fluid textAlign="center">
              <img src={this.state.imageToOpen} />
            </Container>
          </Modal.Content>
          <Modal.Actions>
            <Button color="green" onClick={this.handleClose} inverted>
              <Icon name="checkmark" /> Ok
            </Button>
          </Modal.Actions>
        </Modal>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Header
                as="h2"
                icon="building"
                content="Construcción - Manchetas"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Form>
                <Form.Group>
                  {/* <Form.Field>
                    <label>SAE</label>
                    <Search
                      icon=""
                      loading={this.state.loadingSAESearch}
                      value={this.state.sae}
                      results={this.state.resultsSae}
                      onResultSelect={this.handleResultSaeSelect}
                      onSearchChange={this.handleSearchSaeChange}
                      resultRenderer={renderSearchSaeResults}
                      placeholder="SAE"
                      className="full-width"
                    />
                  </Form.Field> */}
                  <Form.Field>
                    <label>Parte</label>
                    <Search
                      icon=""
                      loading={this.state.loadingParteSearch}
                      value={this.state.parte}
                      results={this.state.resultsParte}
                      onResultSelect={this.handleResultParteSelect}
                      onSearchChange={this.handleSearchParteChange}
                      resultRenderer={renderSearchParteResults}
                      placeholder="Parte"
                      className="full-width"
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Limpiar</label>
                    <Button
                      basic
                      color="red"
                      circular
                      icon="x"
                      onClick={this.handleCleanButton}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Exportar</label>
                    <Button
                      basic
                      color="green"
                      circular
                      icon="file excel outline"
                      onClick={() => {
                        this.setState({ shouldExport: true }, () => {
                          this.fetchData(this.state);
                        });
                      }}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={1}>
            <Grid.Column>
              <ReactTable
                columns={this.state.tableColumns}
                manual
                data={this.state.colecciones}
                loading={this.state.loading}
                pages={Math.ceil(this.state.total / 10)}
                onFetchData={this.fetchData}
                defaultPageSize={100}
                style={{ textAlign: "center" }}
                onSortedChange={async (newSorted, column, shiftKey) => {
                  let direction;
                  if (column.Header == this.state.columna.Header) {
                    direction = this.state.sortDirection ? false : true;
                  } else {
                    direction = false;
                  }

                  this.setState(
                    {
                      columna: column,
                      sortDirection: direction,
                    },
                    () => {
                      console.log(this.state);
                    }
                  );
                  this.fetchData();
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }

  render() {
    if (this.state.ready) {
      if (this.state.task) {
        //goBack={this.goBack} initTask={this.initTask} finishTask={this.finishTask} task={this.state.task} cancelTaskByIncidence={this.cancelTaskByIncidence}/
        return (
          <TaskContainer
            goBack={this.goBack}
            initTask={this.initTask}
            finishTask={this.finishTask}
            task={this.state.task}
            updateTask={this.updateTask}
            cancelTaskByIncidence={this.cancelTaskByIncidence}
          />
        );
      }

      return this.renderList();
    }

    return <LoaderData />;
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(ConstruccionManchetasContainer);
