import React, { Component } from "react";
import { Form, Search } from "semantic-ui-react";
import { formatDate, parseDate } from "react-day-picker/moment";
import DayPickerInput from "react-day-picker/DayPickerInput";

export default class ReportFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        {this.props.filters.map((filter) => {
          switch (filter) {
            case "range":
              return (
                <>
                  <Form.Field key={filter}>
                    <label>Fecha Inicio</label>
                    <DayPickerInput
                      value={this.props.initDay}
                      format="YYYY-MM-DD"
                      formatDate={formatDate}
                      parseDate={parseDate}
                      onDayChange={this.props.changeInitDay}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Fecha Fin</label>
                    <DayPickerInput
                      value={this.props.lastDay}
                      format="YYYY-MM-DD"
                      formatDate={formatDate}
                      parseDate={parseDate}
                      onDayChange={this.props.changeLastDay}
                    />
                  </Form.Field>
                </>
              );
            case "costumer":
              return (
                <Form.Field key={filter}>
                  <label>Cliente</label>
                  <Search
                    icon=""
                    loading={this.props.loading}
                    value={this.props.costumer}
                    results={this.props.costumers}
                    onResultSelect={this.props.onSelectCostumer}
                    onSearchChange={this.props.onSearchCostumerChange}
                    resultRenderer={this.props.costumerResultRenderer}
                    placeholder="Cliente"
                    className="full-width"
                  />
                </Form.Field>
              );
            case "rodillo":
              return (
                <Form.Field key={filter}>
                  <label>Rodillo</label>
                  <Form.Input
                    type="number"
                    value={this.props.rodilloValue}
                    onChange={(e, { _, value }) => {
                      this.props.onRodilloChange(value);
                    }}
                    style={{ width: "130px" }}
                  />
                </Form.Field>
              );
            case "pedido":
              return (
                <Form.Field key={filter}>
                  <label>OT</label>
                  <Form.Input
                    value={this.props.pedidoValue}
                    onChange={(e, { _, value }) => {
                      this.props.onPedidoChange(value);
                    }}
                    style={{ width: "130px" }}
                  />
                </Form.Field>
              );
            case "partida":
              return (
                <Form.Field key={filter}>
                  <label>Partida</label>
                  <Form.Input
                    type="number"
                    value={this.props.partidaValue}
                    onChange={(e, { _, value }) => {
                      this.props.onChangePartida(value);
                    }}
                    style={{ width: "130px" }}
                  />
                </Form.Field>
              );
            case "status":
              return (
                <Form.Field key={filter}>
                  <label>Estado</label>
                  <Form.Dropdown
                    selection
                    options={this.props.statusFilterOptions}
                    onChange={(e, { value }) => {
                      this.props.onStatusChange(value);
                    }}
                    value={this.props.statusValue}
                  />
                </Form.Field>
              );
            case "product":
              return (
                <Form.Field key={filter}>
                  <label>Producto</label>
                  <Form.Dropdown
                    selection
                    options={this.props.products}
                    onChange={(e, { value }) => {
                      this.props.onProductChange(value);
                    }}
                    value={this.props.productValue}
                  />
                </Form.Field>
              );
            case "stage":
              return (
                <Form.Field key={filter}>
                  <label>Etapa</label>
                  <Form.Dropdown
                    selection
                    options={this.props.stages}
                    onChange={(e, { value }) => {
                      this.props.onStageChange(value);
                    }}
                    value={this.props.stage}
                  />
                </Form.Field>
              );
            case "user":
              return (
                <Form.Field>
                  <label>Nombre</label>
                  <Search
                    icon=""
                    loading={this.props.employeeLoading}
                    value={this.props.employee || ""}
                    results={this.props.employeeResults}
                    onResultSelect={this.props.handleEmployeeResultSelect}
                    onSearchChange={this.props.handleEmployeeSearchChange}
                    resultRenderer={this.props.renderEmployeeSearchResults}
                    placeholder="Nombre"
                    className="full-width"
                  />
                </Form.Field>
              );
            default:
              break;
          }
        })}
      </>
    );
  }
}
