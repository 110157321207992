import React, { Component } from "react";
import Feathers from "../../redux/FeathersRedux";
import moment from "moment";
import { Button, Icon } from "semantic-ui-react";
import InfoTaskAssigned from "./InfoTaskAssigned";
import UploadTaskFile from "./uploadTaskFile";
import CommentsModal from "./CommentsModal";
import swal from "sweetalert2";

export default class ButtonsAction extends Component {
  constructor() {
    super();
    this.state = {
      openModalInfo: false,
      requiresFile: false,
      modalComments: false, 
    };
  }

  openModal = () => {
    this.setState({
      openModalInfo: true,
    });
  };

  closeModal = () => {
    this.setState({
      openModalInfo: false,
    });
  };

  //Para finalizar una tarea que solicite un entregable se usara esta funcion
  openFileModal = () => {
    this.setState({
      requiresFile: true,
    });
  };

  closeFileModal = () => {
    this.setState({
      requiresFile: false,
    });
  };

  //Para finalizar una tarea que tenga retardo 
  openModalComments = () => {
    this.setState({
      modalComments: true
    })
  }

  closeModalComments = () => {
    this.setState({
      modalComments: false
    })
  }

  startTask = () => {
    const id = this.props.data.id;
    swal({
      title: "¿Deseas iniciar esta tarea?",
      type: "info",
      showCancelButton: true,
      confirmButtonText: "Sí, iniciar",
      cancelButtonText: "No, cancelar",
      reverseButtons: true,
    }).then((res) => {
      if (res.value) {
        Feathers.service("calendario_tarea")
          .patch(id, {
            status_tarea_id: 2,
          })
          .then((res) => {
            this.props.callback();
          })
          .catch((err) => {
            swal({
              title: "Se produjo un error. Intente de nuevo",
              type: "error",
              confirmButtonText: "ok",
              reverseButtons: true,
            });
          });
      }
    });
  };

  finishTask = () => {
    const id = this.props.data.id;
    swal({
      title: "¿Finalizar tarea?",
      type: "info",
      showCancelButton: true,
      confirmButtonText: "Sí, finalizar",
      cancelButtonText: "No, cancelar",
      reverseButtons: true,
    }).then((res) => {
      if (res.value) {
        Feathers.service("calendario_tarea")
          .patch(id, {
            status_tarea_id: 3,
            fecha_fin_usuario: moment().format("YYYY-MM-DD"),
          })
          .then((res) => {
            this.props.callback();
          })
          .catch((err) => {
            swal({
              title: "Se produjo un error. Intente de nuevo",
              type: "error",
              confirmButtonText: "ok",
              reverseButtons: true,
            });
          });
      }
    });
  };


  buttonsAction() {
    const currentDate =  moment().format("YYYY-MM-DD");
    const dateTask = this.props.data.fecha_fin;
    const validated = moment(currentDate).isAfter(dateTask);
    console.log("porps", this.props, this.props.status === 2 && this.props.isEntregable)
    if (this.props.status === 1) {
      return (
        <>
          <Button type="submit" basic color="grey" onClick={this.openModal}>
            <div>
              <Icon name="info circle" color="grey" />
              <span style={{ fontSize: "14px", marginTop: "1px" }}>
                Ver más
              </span>
            </div>
          </Button>
          <Button type="submit" basic onClick={this.startTask} color="blue">
            <div>
              <Icon name="play circle" color="blue" />
              <span style={{ fontSize: "14px", marginTop: "1px" }}>
                Iniciar
              </span>
            </div>
          </Button>
        </>
      );
    } else if (this.props.status === 2 && this.props.isEntregable) {
      return (
        <>
          <Button type="submit" basic color="grey" onClick={this.openModal}>
            <div>
              <Icon name="info circle" color="grey" />
              <span style={{ fontSize: "14px", marginTop: "1px" }}>
                Ver más
              </span>
            </div>
          </Button>
          <Button
            type="submit"
            basic
            color="green"
            onClick={this.openFileModal}
          >
            <div>
              <Icon name="history" color="green" />
              <span style={{ fontSize: "14px", marginTop: "1px" }}>
                Finalizar
              </span>
            </div>
          </Button>
        </>
      );
    } else if (this.props.status === 3) {
      return (
        <Button type="submit" basic color="grey" onClick={this.openModal}>
          <div>
            <Icon name="info circle" color="grey" />
            <span style={{ fontSize: "14px", marginTop: "1px" }}>Ver más</span>
          </div>
        </Button>
      );
    } else if (this.props.status === 2 && !this.props.isEntregable && !(this.props.data.fecha_fin < currentDate)) {
      return (
        <>
          <Button type="submit" basic color="grey" onClick={this.openModal}>
            <div>
              <Icon name="info circle" color="grey" />
              <span style={{ fontSize: "14px", marginTop: "1px" }}>
                Ver más
              </span>
            </div>
          </Button>
          <Button type="submit" basic color="green" onClick={this.finishTask}>
            <div>
              <Icon name="history" color="green" />
              <span style={{ fontSize: "14px", marginTop: "1px" }}>
                Finalizar
              </span>
            </div>
          </Button>
        </>
      );
    } else if (this.props.status === 2 && !this.props.isEntregable && this.props.data.fecha_fin < currentDate) {
      return (
        <>
          <Button type="submit" basic color="grey" onClick={this.openModal}>
            <div>
              <Icon name="info circle" color="grey" />
              <span style={{ fontSize: "14px", marginTop: "1px" }}>
                Ver más
              </span>
            </div>
          </Button>
          <Button type="submit" basic color="red" onClick={this.openModalComments}>
            <div>
              <Icon name="history" color="red" />
              <span style={{ fontSize: "14px", marginTop: "1px" }}>
                Finalizar 
              </span>
            </div>
          </Button>
        </>
      );
    }
  }

  render() {
    return (
      <div>
        {this.buttonsAction()}
        <InfoTaskAssigned
          open={this.state.openModalInfo}
          close={this.closeModal}
          data={this.props.data}
        />
        <UploadTaskFile
          open={this.state.requiresFile}
          onClose={this.closeFileModal}
          data={this.props.data}
          callback={this.props.callback}
        />
        <CommentsModal
          open={this.state.modalComments}
          onClose={this.closeModalComments}
          data={this.props.data}
          callback={this.props.callback}
        />
      </div>
    );
  }
}
