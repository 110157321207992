import React, { Component } from "react";
import ReactTable from "react-table";
import {
  Button,
  Grid,
  Icon,
  Header,
  Form,
  Label,
  Search,
} from "semantic-ui-react";
import "react-table/react-table.css";
import Toggle from "react-toggle";
import Feathers from "../redux/FeathersRedux";
import DayPicker from "react-day-picker";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import moment from "moment";
import "moment/locale/es";
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";
import { exportToXls } from "../utils/Utils";

const renderEmployeeSearchResults = ({ id, nombre, apellido }) => (
  <Label key={id} content={nombre + " " + apellido} />
);
const renderEmailSearchResults = ({ id, email }) => (
  <Label key={id} content={email} />
);

export default class RawMaterialsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      employees: [],
      total: 0,
      page: 0,
      loading: false,
      pageSize: 10,
      modalOpened: false,
      editModalOpened: false,
      currentRow: {},
      filter: {
        initDay: "",
        lastDay: "",
        employee_id: null,
      },
      $sort: {
        $nameSortDirection: null,
        $lastNameSortDirection: null,
        $hoursSortDirection: null,
        $taskSortDirection: null,
        $incidencesSortdirection: null,
        $unitiesSortDirection: null,
      },

      // search bar objects

      employee: null,
      email: null,
      // search bar loadings

      employeeLoading: null,
      emailLoading: null,
      shouldExport: false,
      // search bar results

      employeeResults: null,
      emailResults: null,
      columna: "",
      sortDirection: false,
    };

    this.fetchData = this.fetchData.bind(this);
  }
  handleSort = (array) => {
    let column = this.state.columna;
    switch (column.Header) {
      case "Nombre":
        array.data.sort((a, b) => {
          const nameA = a.nombre.trimLeft().toUpperCase(); // ignore upper and lowercase
          const nameB = b.nombre.trimLeft().toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Apellido":
        array.data.sort((a, b) => {
          const nameA = a.apellido.toUpperCase(); // ignore upper and lowercase
          const nameB = b.apellido.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Email":
        array.data.sort((a, b) => {
          const nameA = a.email.toUpperCase(); // ignore upper and lowercase
          const nameB = b.email.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Horas Trabajadas":
        array.data.sort((a, b) => {
          const nameA = a.tiempos; // ignore upper and lowercase
          const nameB = b.tiempos; // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Tareas Completadas":
        array.data.sort((a, b) => {
          return a.tareasHechas - b.tareasHechas;
        });
        break;
      case "Incidencias Reportadas":
        array.data.sort((a, b) => {
          return (
            parseInt(a.incidenciasReportadas) -
            parseInt(b.incidenciasReportadas)
          );
        });
        break;
      case "Unidades Completadas":
        array.data.sort((a, b) => {
          return (
            parseInt(a.rodillosTerminados) - parseInt(b.rodillosTerminados)
          );
        });
        break;
      default:
        break;
    }
    if (this.state.sortDirection) {
      array.data.reverse();
    }
    return array;
  };

  handleEmployeeResultSelect = async (e, { result }) => {
    this.setState({ employee: result.nombre + " " + result.apellido });

    await this.setState({
      filter: {
        ...this.state.filterOptions,
        employee_id: result.id,
      },
    });

    this.fetchData(this.state);
  };

  handleEmployeeSearchChange = (e, { value }) => {
    this.setState({ employeeLoading: true, employee: value });

    setTimeout(() => {
      if (this.state.employee.length < 1) return this.resetEmployeeComponent();

      Feathers.service("usuario")
        .find({
          query: {
            $or: [
              {
                nombre: { $like: `%${value}%` },
              },
              {
                apellido: { $like: `%${value}%` },
              },
            ],
            activo: 1,
          },
        })
        .then((res) => {
          this.setState({
            employeeLoading: false,
            employeeResults: res.data,
          });
        });
    }, 500);
  };

  resetEmployeeComponent = () =>
    this.setState({
      employeeLoading: false,
      employeeResults: [],
      employee: "",
    });

  async fetchData(state = {}) {
    let pageSize = state.pageSize || 10;

    let query = {
      $limit: this.state.shouldExport ? "-1" : pageSize,
      $skip: pageSize * state.page,
    };

    if (this.state.$sort.$nameSortDirection !== null) {
      query["$sort"] = {
        nombre: this.state.$sort.$nameSortDirection,
      };
    }

    if (this.state.$sort.$lastNameSortDirection !== null) {
      query["$sort"] = {
        apellido: this.state.$sort.$lastNameSortDirection,
      };
    }

    if (this.state.$sort.$hoursSortDirection !== null) {
      query["$sort"] = {
        tiempos: this.state.$sort.$hoursSortDirection,
      };
    }

    if (this.state.$sort.$taskSortDirection !== null) {
      query["$sort"] = {
        tareasHechas: this.state.$sort.$taskSortDirection,
      };
    }

    if (this.state.$sort.$incidencesSortdirection !== null) {
      query["$sort"] = {
        incidenciasReportadas: this.state.$sort.$incidencesSortdirection,
      };
    }

    if (this.state.$sort.$unitiesSortDirection !== null) {
      query["$sort"] = {
        rodillosTerminados: this.state.$sort.$unitiesSortDirection,
      };
    }

    if (this.state.filter.initDay) {
      let fechainicio = moment(this.state.filter.initDay)
        .hour(0)
        .minute(0)
        .second(0);
      fechainicio = fechainicio.format("YYYY-MM-DD HH:mm:ss");
      query["fechainicio"] = fechainicio; // TODO checar si esto no causa errores
    }

    if (this.state.filter.lastDay) {
      let fechafin = moment(this.state.filter.lastDay)
        .hour(22)
        .minute(60)
        .second(60);
      fechafin = fechafin.format("YYYY-MM-DD HH:mm:ss");
      query["fechafin"] = fechafin;
    }

    if (this.state.filter.employee_id !== null) {
      query["employee_id"] = this.state.filter.employee_id;
    }

    this.setState({ loading: true }, () => {
      Feathers.service("reporte_empleados")
        .find({
          query,
        })
        .then((res) => {
          this.handleSort(res);
          console.log("before", res);
          if (!this.state.shouldExport) {
            this.setState({
              employees: res.data,
              total: res.total,
              loading: false,
              page: state.page,
              pageSize: state.pageSize || 10,
            });
          }
          console.log("after", res);

          if (this.state.shouldExport) {
            exportToXls(res.data, "report_employees");
            this.setState({
              shouldExport: false,
              loading: false,
              page: state.page,
              pageSize: state.pageSize || 100,
            });
          }
        });
    });
  }

  _handleFilterButton = () => {
    this.fetchData(this.state);
  };

  _handleCleanButton = async () => {
    this.resetEmployeeComponent();
    await this.setState({
      employees: [],

      filter: {
        initDay: "",
        lastDay: "",
        employee_id: null,
      },
    });

    this.fetchData(this.state);
  };

  render() {
    return (
      <div>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Header
                as="h2"
                icon="user circle outline"
                content="Reporte de Empleados"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form size={"large"}>
                <Form.Group>
                  <Form.Field>
                    <label>Fecha Inicio</label>
                    <DayPickerInput
                      value={this.state.filter.initDay}
                      format="YYYY-MM-DD"
                      formatDate={formatDate}
                      parseDate={parseDate}
                      onDayChange={(date) => {
                        this.setState({
                          filter: {
                            ...this.state.filter,
                            initDay: date,
                          },
                        });
                      }}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Fecha Fin</label>
                    <DayPickerInput
                      value={this.state.filter.lastDay}
                      format="YYYY-MM-DD"
                      formatDate={formatDate}
                      parseDate={parseDate}
                      onDayChange={(date) => {
                        this.setState({
                          filter: {
                            ...this.state.filter,
                            lastDay: date,
                          },
                        });
                      }}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Nombre</label>
                    <Search
                      icon=""
                      loading={this.state.employeeLoading}
                      value={this.state.employee || ""}
                      results={this.state.employeeResults}
                      onResultSelect={this.handleEmployeeResultSelect}
                      onSearchChange={this.handleEmployeeSearchChange}
                      resultRenderer={renderEmployeeSearchResults}
                      placeholder="Nombre"
                      className="full-width"
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Filtrar</label>
                    <Button
                      basic
                      circular
                      icon="filter"
                      onClick={this._handleFilterButton}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Limpiar</label>
                    <Button
                      basic
                      color="red"
                      circular
                      icon="x"
                      onClick={this._handleCleanButton}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Exportar</label>
                    <Button
                      basic
                      color="green"
                      circular
                      icon="file excel outline"
                      onClick={() => {
                        this.setState({ shouldExport: true }, () => {
                          this.fetchData(this.state);
                        });
                      }}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <ReactTable
                columns={[
                  { Header: "Nombre", accessor: "nombre" },
                  { Header: "Apellido", accessor: "apellido" },
                  { Header: "Email", accessor: "email" },
                  {
                    Header: "Horas T.",
                    id: "tiempo",
                    accessor: (d) => {
                      if (d.tiempos) {
                        return parseFloat(d.tiempos).toFixed(2);
                      } else {
                        return 0;
                      }
                    },
                  },
                  {
                    Header: "T. Completadas",
                    id: "tareasHechas",
                    accessor: (d) => {
                      return d.tareasHechas;
                    },
                  },
                  {
                    Header: "Incidencias R.",
                    id: "incidenciasReportadas",
                    accessor: (d) => {
                      return d.incidenciasReportadas;
                    },
                  },
                  {
                    Header: "Rodillos T.",
                    id: "rodillosTarminados",
                    accessor: (d) => {
                      return d.rodillosTerminados;
                    },
                  },
                  {
                    Header: "Revisión",
                    id: "revision",
                    accessor: (d) => {
                      return d.todasActividades.revision;
                    },
                  },
                  {
                    Header: "Molino",
                    id: "molino",
                    accessor: (d) => {
                      return d.todasActividades.molino;
                    },
                  },
                  {
                    Header: "Desviste",
                    id: "desviste",
                    accessor: (d) => {
                      return d.todasActividades.desviste;
                    },
                  },
                  {
                    Header: "Construcción",
                    id: "construccion",
                    accessor: (d) => {
                      return d.todasActividades.construccion;
                    },
                  },
                  {
                    Header: "Vulcanizado",
                    id: "vulcanizado",
                    accessor: (d) => {
                      return d.todasActividades.vulcanizado;
                    },
                  },
                  {
                    Header: "Desbaste",
                    id: "desbaste",
                    accessor: (d) => {
                      return d.todasActividades.desbaste;
                    },
                  },
                  {
                    Header: "Rectificado",
                    id: "rectificado",
                    accessor: (d) => {
                      return d.todasActividades.rectificado;
                    },
                  },
                  {
                    Header: "Desmonte",
                    id: "desmonte",
                    accessor: (d) => {
                      return d.todasActividades.desmonte;
                    },
                  },
                  {
                    Header: "Calidad",
                    id: "calidad",
                    accessor: (d) => {
                      return d.todasActividades.control_calidad;
                    },
                  },
                  {
                    Header: "Corte",
                    id: "corte",
                    accessor: (d) => {
                      return d.todasActividades.corte;
                    },
                  },
                ]}
                manual
                data={this.state.employees}
                loading={this.state.loading}
                pages={Math.ceil(this.state.total / this.state.pageSize)}
                onFetchData={this.fetchData}
                defaultPageSize={100}
                style={{ textAlign: "center" }}
                resizable={false}
                onSortedChange={async (newSorted, column, shiftKey) => {
                  let direction;
                  if (column.Header == this.state.columna.Header) {
                    direction = this.state.sortDirection ? false : true;
                  } else {
                    direction = false;
                  }

                  this.setState({
                    columna: column,
                    sortDirection: direction,
                  });
                  this.fetchData();
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
